import { useEffect, useState } from 'react';
import { BsCaretDownFill, BsCheck2, BsX } from 'react-icons/bs';

export type SelectOption = {
	label: string | null;
	_id: string | null;
};

type SingleSelectProps = {
	multiple?: false;
	value?: SelectOption;
	onChange: (value: SelectOption) => void;
};

type MultipleSelectProps = {
	multiple: true;
	value?: SelectOption[];
	onChange: (value: SelectOption[]) => void;
};

type SelectProps = {
	options?: SelectOption[];
	search?: boolean;
	onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & (SingleSelectProps | MultipleSelectProps);

export function Select({ value, options, onChange, onClick, multiple }: SelectProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [highlightedIndex, setHighlightedIndex] = useState(0);

	// if (typeof options)

	function clearOptions() {
		multiple ? onChange([]) : onChange({ _id: '', label: '' });
	}

	function selectOption(option: SelectOption) {
		if (multiple) {
			if (value) {
				if (isOptionSelected(option)) {
					onChange(value.filter((o: SelectOption) => o._id !== option._id));
				} else {
					onChange([...value, option]);
				}
			} else {
				// ERROR HAPPENED
			}
		} else {
			if (option !== value) onChange(option);
		}
	}

	function isOptionSelected(option: SelectOption) {
		if (multiple) {
			if (value) {
				return value.some((v: SelectOption) => v._id === option._id);
			} else {
				// ERROR HAPPENED
				return false;
			}
		} else {
			return value && option._id === value._id;
		}
	}

	useEffect(() => {
		if (isOpen) setHighlightedIndex(0);
	}, [isOpen]);

	return (
		<div
			onBlur={() => setIsOpen(false)}
			onClick={() => setIsOpen((prev) => !prev)}
			className="relative min-h-4 flex items-center gap-2 p-2 outline-none rounded-md border-orange-200 border-2 cursor-pointer"
		>
			<span className=" flex-grow flex gap-2 flex-wrap">
				{multiple && value
					? value.map((v: SelectOption) => (
							<button
								key={v._id}
								type="button"
								onClick={(e) => {
									e.stopPropagation();
									selectOption(v);
								}}
								className="flex py-0 px-3 w-auto items-center m-0 bg-orange-300 hover:bg-orange-400 "
							>
								{v.label}
								<span className="">
									<BsX />
								</span>
							</button>
						))
					: typeof value === 'object' && 'label' in value
						? value?.label
						: ''}
			</span>
			<button
				type="button"
				onClick={(e) => {
					e.stopPropagation();
					clearOptions();
				}}
				className="border-none outline-none bg-inherit hover:bg-inherit w-auto m-0 p-0 text-inherit rounded-none pointer"
			>
				<BsX />
			</button>
			<div className=" bg-slate-400 self-stretch w-[0.05rem]"></div>
			<BsCaretDownFill size={10} className="min-w-2 min-h-2" />
			<ul
				className={`select-list absolute glass bg-blue-300/20 p-2 max-h-80 overflow-y-auto m-0 list-none rounded-md border-gray-200 left-0 w-full top-[calc(100%+0.25em)] z-10 ${
					isOpen ? 'block' : 'hidden'
				}`}
			>
				{options &&
					options.map((option, index) => (
						<li
							onClick={(e) => {
								e.stopPropagation();
								selectOption(option);
							}}
							onMouseEnter={() => {
								setHighlightedIndex(index);
							}}
							key={option._id}
							className={`p-1 cursor-pointer rounded my-1 ${isOptionSelected(option) ? 'bg-slate-400/40' : ''} ${
								index === highlightedIndex ? '  bg-blue-600/40' : ''
							}`}
						>
							<span className="relative inline-block rounded border-slate-600 border-2 size-4 mr-1">
								{isOptionSelected(option) && <BsCheck2 size={12} className="" />}
							</span>
							{option.label}
						</li>
					))}
			</ul>
		</div>
	);
}
