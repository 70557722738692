import axios from 'axios';
import { API_ENDPOINT, IndexedArray, _id } from '../functions/Constants';
import { catchError } from '../functions/handleLoginAuth';
import { ContactNote } from '../pages/contacts/components/EditNotes';
import { ContactAssociate } from '../pages/contacts/components/EditWork';

const API_ENDPOINT_CONTACTS = API_ENDPOINT + '/contact';
const API_ENDPOINT_CONTACTS_NOTES = '/note';
const API_ENDPOINT_CONTACTS_LIST = '/list';
const API_ENDPOINT_CONTACTS_ASSOCIATE = '/associated';

// Contacts are clients added to accounts
export type Person = {
	name?: { first?: string; middle?: string; last?: string };
	dob?: string;
	gender?: number;
	type: 1;
};

export type Business = {
	title?: string;
	tagline?: string;
	industry?: string;
	website?: string;
	type: 2;
};

export type Contact = {
	_id: _id;
	email?: string;
	mobile?: string;
	address?: { street?: string; city?: string; postcode?: number; state?: string; country?: string };
	relationship?: number;
	type?: number;
	notes?: ContactNote[];
	associated: {
		person: ContactAssociate[];
		business: ContactAssociate[];
	};
	source?: _id;
	timezone?: string;
	created: {
		date: string;
		by: _id;
	};
} & (Person | Business);

export const relationshipColors: IndexedArray = {
	0: 'default',
	1: 'lead',
	2: 'customer',
	3: 'connection',
};

/**
 * Contacts API endpoint
 */
const Contacts = {
	/**
	 * Get a single object based on an id.
	 *
	 * @param   contact String or object used as an id
	 * @param   setData Set Function used to update an object
	 */
	async get(
		_id: _id | Contact,
		setData: React.Dispatch<React.SetStateAction<Contact | undefined>> | React.Dispatch<React.SetStateAction<Contact>>,
		toast?: any
	) {
		try {
			const response = await axios.get(API_ENDPOINT_CONTACTS + `/${typeof _id === 'string' ? _id : _id._id}`, {
				withCredentials: true,
			});
			if (response.data) {
				setData(response.data);
			}
		} catch (error) {
			catchError(error, toast);
		}
	},

	/**
	 * Get a single object based on an id.
	 *
	 * @param   _id String or object used as an id
	 * @param   setData Set Function used to update an object
	 * @alias this.get()
	 */
	async findById(_id: _id | Contact, setData: React.Dispatch<React.SetStateAction<Contact | undefined>>) {
		this.get(_id, setData);
	},

	/**
	 * Create a new record in the database
	 *
	 * @param   contact Object used
	 */
	async post(contact: Contact, toast?: any) {
		// TODO - show error on fail
		try {
			const response = await axios.post(API_ENDPOINT_CONTACTS, { ...contact }, { withCredentials: true });
			return response.data;
		} catch (error) {
			catchError(error, toast);
		}
	},

	/**
	 * Update a record in the database
	 *
	 * @param   contact Object used
	 */
	async put(contact: Contact, toast?: any) {
		try {
			await axios.put(API_ENDPOINT_CONTACTS + `/${contact._id}`, { ...contact }, { withCredentials: true });
		} catch (error) {
			catchError(error, toast);
		}
	},

	/**
	 * Update partial details of a record in the database
	 *
	 * @param   contact Object used
	 */
	async patch(contact: Contact) {
		this.put(contact);
	},

	/**
	 * Update partial details of a record in the database
	 *
	 * @param   contact Object used
	 */
	async delete(contact: _id | Contact, toast?: any) {
		try {
			await axios.delete(API_ENDPOINT_CONTACTS + `/${typeof contact === 'string' ? contact : contact._id}`, {
				withCredentials: true,
			});
		} catch (error) {
			catchError(error, toast);
		}
	},

	list: {
		/**
		 * Returns all active records
		 *
		 * @returns Array containing basic details from all relevant records
		 */
		async current() {
			try {
				const response = await axios.get(API_ENDPOINT_CONTACTS + API_ENDPOINT_CONTACTS_LIST, { withCredentials: true });
				if (response.data) {
					return response.data;
				}
			} catch (error) {
				catchError(error);
				return [];
			}
		},

		/**
		 * Returns all records, including those marked as deleted
		 *
		 * @returns Array containing basic details from all relevant records
		 */
		async all(): Promise<Contact[] | undefined> {
			try {
				const response = await axios.get(API_ENDPOINT_CONTACTS + API_ENDPOINT_CONTACTS_LIST, { withCredentials: true });
				if (response.data) {
					return response.data;
				}
			} catch (error) {
				catchError(error);
				return [];
			}
		},

		/**
		 * Returns all active records
		 *
		 * @returns Array containing basic details from all relevant records
		 */
		async person() {
			try {
				const response = await axios.get(API_ENDPOINT_CONTACTS + API_ENDPOINT_CONTACTS_LIST + `/person`, { withCredentials: true });
				if (response.data) {
					return response.data;
				}
			} catch (error) {
				catchError(error);
				return [];
			}
		},

		/**
		 * Returns all active records
		 *
		 * @returns Array containing basic details from all relevant records
		 */
		async business() {
			try {
				const response = await axios.get(API_ENDPOINT_CONTACTS + API_ENDPOINT_CONTACTS_LIST + `/business`, {
					withCredentials: true,
				});
				if (response.data) {
					return response.data;
				}
			} catch (error) {
				catchError(error);
				return [];
			}
		},
	},

	notes: {
		/**
		 * Create a new record in the database
		 *
		 * @param   note Object used
		 */
		async post(contact_id: _id, note: ContactNote, toast?: any) {
			// TODO - show error on fail
			try {
				const response = await axios.post(
					API_ENDPOINT_CONTACTS + `/${contact_id}` + API_ENDPOINT_CONTACTS_NOTES,
					{ ...note },
					{ withCredentials: true }
				);
				return response.data;
			} catch (error) {
				catchError(error, toast);
			}
		},

		/**
		 * Update a record in the database
		 *
		 * @param   note Object used
		 */
		async put(contact_id: _id, note: ContactNote, toast?: any) {
			try {
				const response = await axios.put(
					API_ENDPOINT_CONTACTS + `/${contact_id}` + API_ENDPOINT_CONTACTS_NOTES + `/${note._id}`,
					{ ...note },
					{ withCredentials: true }
				);
				return response.data;
			} catch (error) {
				catchError(error, toast);
			}
		},

		/**
		 * Update partial details of a record in the database
		 *
		 * @param   note Object used
		 */
		async patch(contact_id: _id, note: ContactNote, toast?: any) {
			this.put(contact_id, note, toast);
		},

		/**
		 * Delete a record in the database
		 *
		 * @param   contact Object used
		 */
		async delete(contact_id: _id | Contact, note: ContactNote, toast?: any) {
			try {
				await axios.delete(
					API_ENDPOINT_CONTACTS +
						`/${typeof contact_id === 'string' ? contact_id : contact_id._id}` +
						API_ENDPOINT_CONTACTS_NOTES +
						`/${note._id}`,
					{
						withCredentials: true,
					}
				);
			} catch (error) {
				catchError(error, toast);
			}
		},
	},

	associate: {
		/**
		 * Create a new record in the database
		 *
		 * @param   associate Object used
		 */
		async post(contact_id: _id, associate: ContactAssociate, toast?: any) {
			// TODO - show error on fail
			try {
				const response = await axios.post(
					API_ENDPOINT_CONTACTS + `/${contact_id}` + API_ENDPOINT_CONTACTS_ASSOCIATE,
					{ ...associate },
					{ withCredentials: true }
				);
				return response.data;
			} catch (error) {
				catchError(error, toast);
			}
		},

		/**
		 * Update a record in the database
		 *
		 * @param   associate Object used
		 */
		async put(contact_id: _id, associate: ContactAssociate, toast?: any) {
			try {
				const response = await axios.put(
					API_ENDPOINT_CONTACTS + `/${contact_id}` + API_ENDPOINT_CONTACTS_ASSOCIATE + `/${associate._id}`,
					{ ...associate },
					{ withCredentials: true }
				);
				return response.data;
			} catch (error) {
				catchError(error, toast);
			}
		},

		/**
		 * Update partial details of a record in the database
		 *
		 * @param   associate Object used
		 */
		async patch(contact_id: _id, associate: ContactAssociate, toast?: any) {
			this.put(contact_id, associate, toast);
		},

		/**
		 * Delete a record in the database
		 *
		 * @param   contact Object used
		 */
		async delete(contact_id: _id | Contact, associate: ContactAssociate, toast?: any) {
			try {
				await axios.delete(
					API_ENDPOINT_CONTACTS +
						`/${typeof contact_id === 'string' ? contact_id : contact_id._id}` +
						API_ENDPOINT_CONTACTS_ASSOCIATE +
						`/${associate._id}`,
					{
						withCredentials: true,
					}
				);
			} catch (error) {
				catchError(error, toast);
			}
		},
	},
};

export default Contacts;
