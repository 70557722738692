import axios from 'axios';
import { API_ENDPOINT } from '../functions/Constants';
import { catchError } from '../functions/handleLoginAuth';

const API_ENDPOINT_AUTH = API_ENDPOINT + '/auth';

/**
 * Authentication events API endpoint
 */
const Auth = {
	async post(data: { username: string; password: string }, setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>, toast?: any) {
		// Correct login
		try {
			const response = await axios.post(API_ENDPOINT_AUTH, data, { withCredentials: true });
			setIsLoggedIn(true);

			const toastId = toast.open(
				<div className=" flex gap-2 bg-green-300 text-emerald-600 p-4 rounded-lg shadow-lg">
					<div></div>
					<div>
						<p>Success!</p>
						<p>{response.data.message}.</p>
					</div>
				</div>,
				5000
			);
		} catch (error: unknown) {
			catchError(error, toast);
		}
	},

	async login(data: { username: string; password: string }, setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>, toast?: any) {
		this.post(data, setIsLoggedIn, toast);
	},

	/**
	 * Invalidate an authenticated session
	 *
	 * Default behavior is to terminate the current session
	 *
	 * @param _id optional value used to terminate a specific session
	 */
	async delete(_id?: string, toast?: any) {
		try {
			const response = await axios.delete(API_ENDPOINT_AUTH + (_id ? `/${_id}` : ''), { withCredentials: true });
			if (response && 'result' in response.data && response.data.result == 1) {
				window.location.href = '/';
			} else {
				// toast.o
			}
		} catch (error: unknown) {
			catchError(error, toast);
		}
	},

	async logout(_id?: string, toast?: any) {
		this.delete(_id, toast);
	},
};

export default Auth;
