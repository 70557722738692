import axios from 'axios';
import { useEffect, useState } from 'react';
import { IndexedArray, API_ENDPOINT } from '../../../functions/Constants';
import { catchError } from '../../../functions/handleLoginAuth';
import Auth from '../../../api/Auth';
import { useToast } from '../../../components/toast/ToastService';

export default () => {
	// const [tableItems, setTableItems] = useState<number>(0);
	const [sessionHistory, setSessionHistory] = useState<any[]>([]);
	const toast = useToast();

	const handleContactTableCallback = (data: any) => {
		if (data?.length > 0) {
			// setTableItems(data.length);
			setSessionHistory(data);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(API_ENDPOINT + '/auth/list', { withCredentials: true });
				handleContactTableCallback(response.data);
			} catch (error) {
				catchError(error);
			}
		};

		fetchData();
	}, []);

	return (
		<>
			<div className="flex flex-wrap flex-row">
				<div className="w-1/2 p-3">
					<label>Username</label>
					<input type="text" autoComplete="username" />
				</div>
				<div className="w-1/2 p-3">
					<label>Current Password</label>
					<input type="password" autoComplete="current-password" />
				</div>
				<div className="w-1/2 p-3">
					<label>Security Question</label>
					<input type="text" />
				</div>
				<div className="w-1/2 p-3">
					<label>New Password</label>
					<input type="password" autoComplete="new-password" />
				</div>
				<div className="w-1/2 p-3">
					<label>Security Answer</label>
					<input type="text" />
				</div>
				<div className="w-1/2 p-3">
					<label>Confirm New Password</label>
					<input type="password" autoComplete="new-password" />
				</div>
			</div>
			{/* TODO retrieve session data from server and display in a table */}
			<br />
			<div className="w-1/2 p-3">
				<label>Enable MFA - (highly reccomended)</label>
				<input type="checkbox" name="mfa" />
			</div>
			<div className="w-1/2 p-3">
				<label>Allow password to be reset</label>
				<input type="checkbox" name="reset" />
			</div>
			<br />
			<br />
			<div className=" mt-4">
				<h3>Session History</h3>
				<table className=" w-full mt-6 h-96 block overflow-y-scroll">
					<thead>
						<tr>
							<th>Device</th>
							<th>Location</th>
							<th>Date</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						{sessionHistory &&
							sessionHistory.map((session: any) => (
								<tr>
									<td>
										Windows
										<br />
										{session.useragent}
									</td>
									<td>
										McDowall, QLD, Australia
										<br />
										<span className=" text-slate-400 font-mono">{session.ip}</span>
									</td>
									<td>{new Date(session.auth.time).toLocaleString()}</td>
									<td>{session.auth && renderSwitch(session.auth.result, session, toast)}</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</>
	);
};

// TODO - pass user settings data back so we can update the user from here.
function renderSwitch(param: number, session: any, toast?: any) {
	switch (param) {
		case 0:
			return (
				<button type="button" className="" onClick={() => Auth.delete(session._id, toast)}>
					Log out
				</button>
			);
		case -1:
			return <p>Logged out</p>;
		case -2:
			return <p>Forcefully Logged out</p>;
		default:
			if (session.auth.expiry > new Date()) {
				return <p>Expired</p>;
			} else {
				return <p>Unknown</p>;
			}
	}
}
