import { BsFillExclamationTriangleFill, BsFillExclamationOctagonFill, BsFillExclamationDiamondFill, BsCheckCircleFill, BsInfoCircleFill,  } from 'react-icons/bs';

interface Props {
    children: string;
    style?: string;
    onClose: () => void;
}

const Alert = ({children, onClose, style = 'primary'}: Props) => {
  return (
    <div className={"alert alert-dismissible d-flex align-items-center fade show alert-"+style}
    role="alert"
    >
      {style.includes('success') && <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Success:"><BsInfoCircleFill/></svg>}
      {style.includes('danger') && <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Error:"><BsFillExclamationOctagonFill/></svg>}
      <div>
        { children }
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={onClose}></button>
      </div>
    </div>
  )
}

export default Alert