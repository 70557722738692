import axios from 'axios';
import { _id, API_ENDPOINT } from '../functions/Constants';
import { catchError } from '../functions/handleLoginAuth';

const API_ENDPOINT_USERS = API_ENDPOINT + '/user';

// Account holders with login are users
export interface User {
	_id: _id;
	name?: { first?: string; middle?: string; last?: string };
	dob?: string;
	username: string;
	gender?: number;
	email?: string;
	settings?: [{ key?: string }]; // This allows any string key
	phone?: string;
	mobile?: string;
	address?: { street?: string; city?: string; postcode?: number; state?: string; country?: string };
}

/**
 * Users API endpoint
 */
const Users = {
	/**
	 * Get a single object based on an id.
	 *
	 * @param   contact String or object used as an id
	 * @param   setData Set Function used to update an object
	 */
	async get(
		contact: _id | User,
		setData: React.Dispatch<React.SetStateAction<User>> | React.Dispatch<React.SetStateAction<User>>,
		toast?: any
	) {
		try {
			const response = await axios.get(API_ENDPOINT_USERS + `/${typeof contact === 'string' ? contact : contact._id}`, {
				withCredentials: true,
			});
			if (response.data) {
				setData(response.data);
			}
		} catch (error) {
			catchError(error, toast);
		}
	},

	/**
	 * Get a single object based on an id.
	 *
	 * @param   _id String or object used as an id
	 * @param   setData Set Function used to update an object
	 * @alias this.get()
	 */
	async findById(_id: _id | User, setData: React.Dispatch<React.SetStateAction<User>>) {
		this.get(_id, setData);
	},

	/**
	 * Get a single object based on an id.
	 *
	 * @param   contact String or object used as an id
	 * @param   setData Set Function used to update an object
	 */
	async current(setData: React.Dispatch<React.SetStateAction<User>> | React.Dispatch<React.SetStateAction<User>>, toast?: any) {
		try {
			const response = await axios.get(API_ENDPOINT_USERS + `/current`, {
				withCredentials: true,
			});
			if (response.data) {
				setData(response.data);
			}
		} catch (error) {
			catchError(error, toast);
		}
	},

	/**
	 * Create a new record in the database
	 *
	 * @param   contact Object used
	 */
	async post(data: User, toast?: any) {
		// TODO - show error on fail
		try {
			const response = await axios.post(API_ENDPOINT_USERS, { ...data }, { withCredentials: true });
			return response.data;
		} catch (error) {
			catchError(error, toast);
		}
	},

	/**
	 * Update a record in the database
	 *
	 * @param   data Object used
	 */
	async put(data: User, toast?: any) {
		try {
			await axios.put(API_ENDPOINT_USERS + `/${data._id}`, { ...data }, { withCredentials: true });
		} catch (error) {
			catchError(error, toast);
		}
	},

	/**
	 * Update partial details of a record in the database
	 *
	 * @param   data Object used
	 */
	async patch(data: User) {
		this.put(data);
	},

	/**
	 * Update partial details of a record in the database
	 *
	 * @param   contact Object used
	 */
	async delete(contact: _id | User, toast?: any) {
		try {
			await axios.delete(API_ENDPOINT_USERS + `/${typeof contact === 'string' ? contact : contact._id}`, {
				withCredentials: true,
			});
		} catch (error) {
			catchError(error, toast);
		}
	},
};

export default Users;
