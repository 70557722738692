import { useEffect, useState } from 'react';
import {
	API_ENDPOINT,
	CONTACT_BUSINESS,
	CONTACT_PERSON,
	ContactTableProps,
	IndexedArray,
	STATE_EDIT,
	STATE_MANAGE,
	STATE_ADD,
} from '../../functions/Constants';
import {
	BsAt,
	BsChevronLeft,
	BsChevronRight,
	BsFillBuildingFill,
	BsFillPersonFill,
	BsFillTrash3Fill,
	BsGrid1X2Fill,
	BsPencilSquare,
	BsTelephoneFill,
	BsThreeDots,
} from 'react-icons/bs';
import axios from 'axios';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Dialog from '../../components/Dialog';
import { catchError } from '../../functions/handleLoginAuth';
import Dropdown from '../../components/Dropdown';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../state/store';
import { update } from '../../state/viewSlice';
import { Contact, relationshipColors } from '../../api/Contacts';
// import TooltipWrapper from '../../components/TooltipWrapper';
// import ConfirmationModal from '../../components/ConfirmationModal';

const ContactTable: React.FC<ContactTableProps> = ({}) => {
	const [tableItems, setTableItems] = useState<number>(0);
	const [contactTableData, setContactTableData] = useState<Contact[]>([]);
	const [showModal, setShowModal] = useState<boolean>(false);

	///

	const [contacts, setContacts] = useState<Contact[]>([]);
	const [totalContacts, setTotalContacts] = useState<number>(0);
	const [page, setPage] = useState<number>(1);
	const [limit, setLimit] = useState<number>(25);
	const [search, setSearch] = useState<string>('');
	const [sortField, setSortField] = useState<string>('name.first');
	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [filter, setFilter] = useState<string | null>(null);

	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		try {
	// 			const response = await axios.get(API_ENDPOINT + '/contact', {
	// 				params: { page, limit, search, sortField, sortOrder, filter },
	// 				withCredentials: true,
	// 			});
	// 			setContacts(response.data.data);
	// 			setContactTableData(response.data.data);
	// 			setTotalContacts(response.data.total);
	// 		} catch (error) {
	// 			console.error('Error fetching contacts:', error);
	// 		}
	// 	};

	// 	fetchData();
	// }, [page, limit, search, sortField, sortOrder, filter]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(API_ENDPOINT + '/contact', {
					params: { limit: 1000 }, // Fetch a large set of data
					withCredentials: true,
				});
				setContacts(response.data.data);
				setTotalContacts(response.data.total);
			} catch (error) {
				console.error('Error fetching contacts:', error);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const filteredData = contacts
			.filter((contact) => {
				// Check if contact is of type Person and has a name
				if (contact.type == CONTACT_PERSON && contact.name) {
					return `${contact.name.first} ${contact.name.last}`.toLowerCase().includes(search.toLowerCase());
				}
				// Check if contact is of type Business and has a title
				if (contact.type == CONTACT_BUSINESS && contact.title) {
					return contact.title.toLowerCase().includes(search.toLowerCase());
				}
				return false;
			})
			.sort((a, b) => {
				const order = sortOrder === 'asc' ? 1 : -1;
				// Ensure that the sortField exists before accessing it
				const aValue = a[sortField as keyof typeof a];
				const bValue = b[sortField as keyof typeof b];

				// Convert the values to strings and compare, fallback to empty string if undefined
				return String(aValue ?? '').localeCompare(String(bValue ?? '')) * order;
			})
			.slice((page - 1) * limit, page * limit);

		setContactTableData(filteredData);
	}, [contacts, search, sortField, sortOrder, page, limit]);

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
		setPage(1); // Reset to first page on new search
	};

	const handleSort = (field: string) => {
		const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
		setSortField(field);
		setSortOrder(order);
		setPage(1); // Reset to first page on new sort
	};

	const handleFilter = (value: string | null) => {
		setFilter(value);
		setPage(1); // Reset to first page on new filter
	};
	///

	// Redux
	const viewState = useSelector((state: RootState) => state.example.value);
	const dispatch = useDispatch();

	const handleContactTableCallback = (data: any) => {
		if (data?.length > 0) {
			setTableItems(data.length);
			setContactTableData(data);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(API_ENDPOINT + '/contact', { withCredentials: true });
				handleContactTableCallback(response.data);
			} catch (error) {
				catchError(error);
			}
		};

		fetchData();
	}, []);

	const deleteContact = async (id: string) => {
		try {
			await axios.delete(`${API_ENDPOINT}/contact/${id}`, { withCredentials: true });
			setContactTableData(contactTableData.filter((contact) => contact._id !== id));
		} catch (error) {
			console.error('Error deleting contact:', error);
		}
	};

	return (
		<>
			<div className="clear-both"></div>
			<h3>Contact Manager</h3>

			{<ContactManagerKey />}
			<div className="contact-table container bg-stone-50 dark:bg-dark-600 rounded outline outline-2 outline-neutral-100 dark:outline-dark-800 mt-4">
				<div className=" m-3">
					<p className="float-left">Showing {totalContacts} Contacts</p>
					<span className="mx-4">
						<button
							className="align-middle no-btn bg-slate-200 rounded-md p-1"
							disabled={page === 1}
							onClick={() => setPage(page - 1)}
						>
							<BsChevronLeft className="" size={20} />
						</button>
						<span className=" px-2 align-middle">Page {page}</span>
						<button
							className="align-middle no-btn bg-slate-200 rounded-md p-1"
							disabled={page * limit >= totalContacts}
							onClick={() => setPage(page + 1)}
						>
							<BsChevronRight className="" size={20} />
						</button>
					</span>
					{/* <div className="float-left">
					</div> */}
					<input className=" w-1/4" type="text" placeholder="Search..." value={search} onChange={handleSearch} />
					<select className=" w-20" onChange={(e) => setLimit(parseInt(e.target.value))} defaultValue={limit}>
						<option value="10">10</option>
						<option value="25">25</option>
						<option value="50">50</option>
					</select>
					<Button action={() => setShowModal(true)} style="primary float-right" text="Add Contact"></Button>
				</div>
				<div className="clear-both clear"></div>
				<div className="  max-h-[75vh] overflow-y-scroll">
					<table className=" table w-full text-left">
						<thead className="table-group-divider">
							<tr className=" bg-slate-200 dark:bg-dark-800">
								<th scope="col" className=" cursor-pointer px-3" onClick={() => handleSort('name.first')}>
									&nbsp;
								</th>
								<th scope="col" className=" cursor-pointer px-3" onClick={() => handleSort('email')}>
									Name
								</th>
								<th scope="col" className=" cursor-pointer px-3" onClick={() => handleSort('tags')}>
									Contact
								</th>
								{/* <th scope="col" className="  w-1/3">
								Notes
							</th> */}
								<th scope="col" className=" px-3">
									Tags
								</th>
							</tr>
						</thead>
						<tbody className="table-group-divider">
							{/* Contact rows */}
							{contactTableData &&
								contactTableData.map((contact: Contact, index: number) => (
									<tr key={index}>
										{/* Action column */}
										<td className=" w-12">
											{/* <button onClick={() => action(STATE_EDIT, contact.id !== undefined ? contact.id : 0)} className="btn btn-link">Edit</button> */}
											<div className="btn-group">
												{/* <button type="button" className="btn-svg min-w-min" data-bs-toggle="dropdown" aria-expanded="false">
											<BsThreeDots />
											<span className=" hidden">Toggle Dropdown</span>
										</button> */}
												<input type="checkbox" className=" mr-2" />
												<Dropdown
													className="left-0"
													object={[
														{
															group: [
																{
																	name: 'Edit',
																	// description: 'Update contact details',
																	func: () =>
																		dispatch(
																			update({
																				state: STATE_EDIT,
																				type: contact.type,
																				_id: contact._id,
																			})
																		),
																	icon: <BsPencilSquare size={32} className=" text-emerald-500" />,
																},
																{
																	name: 'Manage',
																	// description: 'Create invoices, send emails, view projects',
																	func: () =>
																		dispatch(
																			update({
																				state: STATE_MANAGE,
																				type: contact.type,
																				_id: contact._id,
																			})
																		),
																	icon: <BsGrid1X2Fill size={32} className=" text-blue-500" />,
																},
															],
														},
														{
															group: [
																{
																	name: 'Delete',
																	// description: 'Move this contact to the bin',
																	func: () => deleteContact(contact._id),
																	icon: <BsFillTrash3Fill size={32} className=" text-red-500" />,
																},
															],
														},
													]}
												>
													{/* <button type="button" className="btn-svg min-w-min"> */}
													<BsThreeDots />
													{/* </button> */}
												</Dropdown>
											</div>
										</td>
										{/* Contact Name */}
										<td className="align-middle">
											{contact.type === CONTACT_PERSON ? (
												<div className="group inline-block m-1">
													<BsFillPersonFill
														size={28}
														className={
															' inline-block contact-relationship contact-relationship-' +
															relationshipColors[contact.relationship ?? 0]
														}
													/>
													<span className="tooltip origin-left left-14 mx-2 group-hover:scale-100">Person</span>
												</div>
											) : contact.type === CONTACT_BUSINESS ? (
												<div className="group inline-block m-1">
													<BsFillBuildingFill
														size={28}
														className={
															' inline-block group contact-relationship contact-relationship-' +
															relationshipColors[contact.relationship ?? 0]
														}
													/>
													<span className="tooltip origin-left left-14 mx-2 group-hover:scale-100">Business</span>
												</div>
											) : (
												''
											)}
											{contact.type === CONTACT_PERSON &&
												'name' in contact &&
												contact.name?.first + ' ' + contact.name?.last}
											{contact.type === CONTACT_BUSINESS && 'title' in contact && contact.title}
											{/* TODO - Show business name(s) under contact name */}
										</td>
										{/* Contact details */}
										<td className="align-middle">
											{contact.email && (
												<span className=" cursor-pointer">
													{/* TODO - When clicked, open a dialog to send an email with this contact selected as the recipient */}
													<BsAt className=" inline-block" size={18} /> {contact.email}
												</span>
											)}{' '}
											{contact.email && contact.mobile && <br />}{' '}
											{contact.mobile && (
												<span>
													<BsTelephoneFill className=" inline-block" size={18} /> {contact.mobile}
												</span>
											)}
										</td>
										{/* Notes */}
										{/* <td className="">{contact.personal_notes}</td> */}
										{/* Tags */}
										<td>TODO</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
			<Modal show={showModal} title="Add a new Contact" onClose={() => setShowModal(false)} btn1={() => setShowModal(false)}>
				<p>Which contact would you like to create?</p>
				<button className="btn btn-primary" onClick={() => dispatch(update({ state: STATE_ADD, type: CONTACT_PERSON }))}>
					Person
				</button>
				&nbsp;
				<button className="btn btn-primary" onClick={() => dispatch(update({ state: STATE_ADD, type: CONTACT_BUSINESS }))}>
					Organisation
				</button>
			</Modal>
			{/* <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
				<Modal.Header>
					<Modal.Title>Add a new Contact</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Which contact would you like to create?</p>
					<button className="btn btn-primary" onClick={() => action(1, STATE_EDIT, 0)}>
						Person
					</button>
					&nbsp;
					<button className="btn btn-primary" onClick={() => action(2, STATE_EDIT, 0)}>
						Business
					</button>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-outline-danger" onClick={() => setShowModal(false)}>
						Close
					</button>
				</Modal.Footer>
			</Modal> */}
		</>
	);
};

export default ContactTable;

const ContactManagerKey = () => {
	return (
		<>
			<div className="contact-manager-key key float-right">
				<div>
					Icon colors represent your relationship with the person/business
					<ul>
						<li className="contact-relationship contact-relationship-default">
							<span></span>No Relationship
						</li>
						<li className="contact-relationship contact-relationship-lead">
							<span></span>Lead
						</li>
						<li className="contact-relationship contact-relationship-customer">
							<span></span>Customer
						</li>
						<li className="contact-relationship contact-relationship-connection">
							<span></span>Connection
						</li>
					</ul>
				</div>
			</div>
			<div style={{ clear: 'both' }}></div>
		</>
	);
};
