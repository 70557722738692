import { BsPlusCircleDotted } from 'react-icons/bs';
import { User } from '../../../api/Users';

export default ({ dataState }: { dataState: [User, React.Dispatch<React.SetStateAction<User>>] }) => {
	const [data, setData] = dataState;

	const handleChange = (key: string, value: string | Number) => {
		setData((prevState) => ({
			...prevState,
			[key]: value,
		}));
	};

	return (
		<>
			<div className="flex flex-wrap flex-row">
				<div className="border-2 border-slate-300 bg-stone-100 py-32 px-24 cursor-pointer">
					<BsPlusCircleDotted size={32} />
					{/* <form onSubmit={() => console.log(data)}>
						{data.settings &&
							data.settings.map((setting: string) => (
								<div key={setting}>
									<label>{setting}</label>
									<input type="text" value={data[setting]} onChange={(e) => handleChange(key, e.target.value)} />
								</div>
							))}
						<button type="submit">Save</button>
					</form> */}
				</div>
				<div className="w-full">
					<div className="w-full p-3">
						<label>SMTP method</label>
						<select>
							<option>Gmail</option>
							<option>Outlook</option>
							<option>Sendgrid</option>
							<option>Axigen</option>
							<option>Custom SMTP</option>
						</select>
					</div>
					<div className="w-full p-3">
						<label>Service Name</label>
						<input type="text" autoComplete="off" />
					</div>
					<div className="w-full p-3">
						<label>Hostname</label>
						<input type="text" autoComplete="off" />
					</div>
					<div className="w-full p-3">
						<label>Port</label>
						<input type="text" autoComplete="off" placeholder="465" />
					</div>
					<div className="w-full p-3">
						<label>Username</label>
						<input type="text" autoComplete="off" />
					</div>
					<div className="w-full p-3">
						<label>Password</label>
						<input type="password" autoComplete="off" />
						<div className="small-help ">You must generate an app password and use that instead</div>
					</div>
				</div>
			</div>
		</>
	);
};
