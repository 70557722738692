import { _id, DataState } from './Constants';

const isFormEvent = (e: any): e is React.FormEvent<HTMLFormElement> => {
	return e && e.currentTarget && e.currentTarget.tagName === 'FORM';
};

const handleSubmit = async <T extends { _id: string }>(
	e: React.FormEvent<HTMLFormElement> | React.DOMAttributes<HTMLButtonElement> | undefined,
	dataState: DataState<T>,
	api: {
		post?: (_id: T, toast?: any) => Promise<any>;
		put?: (_id: T, toast?: any) => Promise<any>;
		patch?: (_id: T, toast?: any) => Promise<any>;
		postById?: (_id: _id, toast?: any) => Promise<any>;
		putById?: (_id: _id, toast?: any) => Promise<any>;
		patchById?: (_id: _id, toast?: any) => Promise<any>;
	},
	setSubmitLock?: (value: boolean) => void,
	toast?: any
) => {
	const [data, setData] = dataState;
	if (isFormEvent(e)) {
		if (setSubmitLock) setSubmitLock(true);
		e.preventDefault();
	}
	if ((isFormEvent(e) && !e.currentTarget.checkValidity()) || data === undefined) {
		if (toast) {
			const toastId = toast.open(
				<div className=" flex gap-2 bg-red-300 text-red-500 p-4 rounded-lg shadow-lg">
					<div></div>
					<div>
						<p>Missing Fields!</p>
						<p>Please complete all required fields.</p>
					</div>
				</div>,
				5000
			);
		}
	} else {
		if ('_id' in data && data._id) {
			if (api.patch) api.patch(data);
			if (api.putById) api.putById(data._id);
			if (toast) {
				const toastId = toast.open(
					<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
						<div></div>
						<div>
							<p>Saved Successfully!</p>
							<p>New contact details saved successfully.</p>
						</div>
					</div>,
					5000
				);
			}
		} else {
			if (api.post) api.post(data);
			if (api.postById) api.postById(data._id);
			if (toast) {
				const toastId = toast.open(
					<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
						<div></div>
						<div>
							<p>Created Successfully!</p>
							<p>New contact created successfully.</p>
						</div>
					</div>,
					5000
				);
			}
		}
	}
	console.log();
	if (setSubmitLock) setSubmitLock(false);
};

export default handleSubmit;
