import { BsClockHistory, BsPerson, BsPlusCircleDotted } from 'react-icons/bs';
import { Project } from '../../../functions/Constants';
import { useEffect, useState } from 'react';
import Projects from '../../../api/Projects';
import { Contact } from '../../../api/Contacts';

export default ({ dataState }: { dataState: [Contact, React.Dispatch<React.SetStateAction<Contact>>] }) => {
	const [contactData, setContactData] = dataState;
	const [data, setData] = useState<Project>({
		_id: null,
		title: null,
		description: null,
		priority: 3,
		status: 1, // Not started
		assigned: {
			contacts: [],
			users: [],
		},
		pricing: {
			cost: null,
			method: null,
			direction: null,
			interval: null,
			units: null,
			time: null,
			promotion: null,
			estimated: {
				cost: null,
				time: null,
			},
		},
		due: null,
	});
	// useEffect(async () => {
	// 	await Projects.getByContact(contactData._id, setData);
	// }, []);

	return (
		<>
			<div className="flex flex-wrap flex-row">
				<div className="w-full flex">
					{/* TODO - Flip animation when clicked to reveal settings */}
					<span className="border-2 border-slate-300 bg-slate-50 h-[32em] w-[24em]  cursor-pointer min-w-32 block mx-2 relative">
						<img src="https://picsum.photos/160" className="m-auto py-1 rounded-2xl border-2 border-slate-100 " />
						<div className="p-4">
							<h3 className="text-xl">Website design</h3>
						</div>
						<div className="p-4">
							<ul>
								<li>
									<span>$7,200</span>
								</li>
								<li>
									<BsClockHistory size={18} className="inline" />
									<span className="ml-2">86 hours</span>
								</li>
								<li>
									<BsPerson size={16} className="inline" />
									<span className="ml-2">Harry Smart</span>
								</li>
								<li>
									{/* <BsTelephoneFill size={16} className="inline mx-1" /> */}
									<span className="bg-amber-400 text-black px-4">In Progress</span>
								</li>
								<li className="absolute bottom-0">
									<div className="mb-1">
										<span>Due on 12/09/2024</span>
									</div>
								</li>
							</ul>
						</div>
						{/* <p className=" py-4 font-bold">Add work profile</p>
						<BsPlusCircleDotted size={24} className=" m-auto" /> */}
					</span>
					{/* TODO - Flip animation when clicked to reveal settings */}
					<span className="border-2 border-slate-300 bg-slate-50 h-[32em] w-[24em]  cursor-pointer min-w-32 block mx-2 relative">
						<img src="https://picsum.photos/160" className="m-auto py-1 rounded-2xl border-2 border-slate-100 " />
						<div className="p-4">
							<h3 className="text-xl">SEO review</h3>
						</div>
						<div className="p-4">
							<ul>
								<li>
									<span>$320</span>
								</li>
								<li>
									<BsClockHistory size={18} className="inline" />
									<span className="ml-2">46 minutes</span>
								</li>
								<li>
									<BsPerson size={16} className="inline" />
									<span className="ml-2">Ragenia George</span>
								</li>
								<li>
									{/* <BsPerson size={16} className="inline" /> */}
									<span className="bg-amber-400 text-black px-4">In Progress</span>
								</li>
								<li className="absolute bottom-0">
									<div className="mb-1">
										<span>Due in 38 hours</span>
									</div>
								</li>
							</ul>
						</div>
						{/* <p className=" py-4 font-bold">Add work profile</p>
						<BsPlusCircleDotted size={24} className=" m-auto" /> */}
					</span>
					<span className="border-2 border-slate-200 bg-stone-100 h-[32em] w-[24em] cursor-pointer min-w-32 block mx-2 relative">
						<div className=" top-1/3 m-auto block relative">
							<p className=" py-4 text-center text-lg font-bold">Add new project</p>
							<BsPlusCircleDotted size={48} className=" m-auto" />
						</div>
					</span>
				</div>
			</div>
		</>
	);
};
