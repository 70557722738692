import { ReactNode, useState } from 'react';
import ToastContext from './ToastService';
import { BsXLg } from 'react-icons/bs';

interface Props {
	component: ReactNode;
	id: number;
}

export default function ToastProvider({ children }: { children: ReactNode }) {
	const [toasts, setToasts] = useState<Props[]>([]);

	const open = (component: any, timeout = 5000) => {
		const id = Date.now();
		setToasts((toasts) => [...toasts, { id, component }]);

		setTimeout(() => close(id), timeout);
		return id;
	};

	const close = (id: number) => setToasts((toasts) => toasts.filter((toast) => toast.id !== id));

	return (
		<ToastContext.Provider value={{ open, close }}>
			{children}
			<div className="fixed space-y-2 bottom-4 right-4 z-40 transition-all duration-200 ease-linear">
				{toasts.map(({ component, id }) => (
					<div key={id} className="relative">
						<button
							onClick={() => close(id)}
							className="  w-fit btn-svg absolute top-1 right-1 p-1 rounded-lg bg-gray-200/20 text-gray-800/60"
						>
							<BsXLg size={16} />
						</button>
						{component}
					</div>
				))}
			</div>
		</ToastContext.Provider>
	);
}
