import axios, { AxiosError } from 'axios';
import { API_ENDPOINT } from './Constants.tsx';
import { toastServerError, toastUnknownError } from '../components/toast/ToastTemplates.tsx';

// export const handleLoginSubmit = async (
// 	username: string,
// 	password: string,
// 	setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>,
// 	toast: any
// ) => {
// 	const data = {
// 		username: username,
// 		password: password,
// 	};

// 	// Correct login
// 	try {
// 		const response = await axios.post(API_ENDPOINT + '/auth', data, { withCredentials: true });
// 		setIsLoggedIn(true);

// 		const toastId = toast.open(
// 			<div className=" flex gap-2 bg-green-300 text-emerald-600 p-4 rounded-lg shadow-lg">
// 				<div></div>
// 				<div>
// 					<p>Success!</p>
// 					<p>{response.data.message}.</p>
// 				</div>
// 			</div>,
// 			5000
// 		);
// 	} catch (error: unknown) {
// 		catchError(error, toast);
// 	}
// };

// export const handleLogout = async () => {
// 	try {
// 		await axios.delete(API_ENDPOINT + '/auth', { withCredentials: true });
// 		window.location.href = '/';
// 	} catch (error: unknown) {
// 		catchError(error);
// 	}
// };

export function catchError(error: AxiosError | unknown, toast?: any) {
	if (axios.isAxiosError(error)) {
		const axiosError = error as AxiosError;

		if (toast) {
			if (axiosError.response) {
				if (
					axiosError.response.data !== null &&
					typeof axiosError.response.data === 'object' &&
					'message' in axiosError.response.data &&
					typeof axiosError.response.data.message === 'string'
				) {
					const toastId = toast.open(
						<div className=" flex gap-2 bg-red-300 text-red-500 p-4 rounded-lg shadow-lg">
							<div></div>
							<div>
								<p>Error</p>
								<p>{axiosError.response.data.message}</p>
							</div>
						</div>,
						5000
					);
				} else {
					// TODO - log all unknown errros somewhere
					const toastId = toast.open(toastUnknownError, 5000);
				}
			}
		}
	} else {
		// TODO - log all unknown errros somewhere
		if (toast) {
			const toastId = toast.open(toastServerError, 5000);
		}
	}
}
