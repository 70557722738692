import {
	BsCodeSlash,
	BsJustify,
	BsListOl,
	BsListUl,
	BsQuote,
	BsTextCenter,
	BsTextLeft,
	BsTextRight,
	BsTypeBold,
	BsTypeH1,
	BsTypeH2,
	BsTypeH3,
	BsTypeH4,
	BsTypeItalic,
	BsTypeUnderline,
} from 'react-icons/bs';
import { BaseEditor, Transforms, Element, Editor } from 'slate';
import { Editable, ReactEditor } from 'slate-react';

export type CustomElement = { type: string; align?: string; children: CustomText[] };
export type CustomText = { text: string };
declare module 'slate' {
	interface CustomTypes {
		Editor: BaseEditor & ReactEditor;
		Element: CustomElement;
		Text: CustomText;
	}
}

interface ElementProps {
	attributes: any;
	children: React.ReactNode;
	element: { type: string; align?: string };
}

// Elements are block-level format options
const ElementX: React.FC<ElementProps> = ({ attributes, children, element }) => {
	const style = { textAlign: element.align };
	switch (element.type) {
		case 'h1':
			return (
				<h1 style={style} {...attributes}>
					{children}
				</h1>
			);
		case 'h2':
			return (
				<h2 style={style} {...attributes}>
					{children}
				</h2>
			);
		case 'h3':
			return (
				<h3 style={style} {...attributes}>
					{children}
				</h3>
			);
		case 'h4':
			return (
				<h4 style={style} {...attributes}>
					{children}
				</h4>
			);
		case 'blockquote':
			return (
				<blockquote style={style} {...attributes} className=" ml-3 p-1 bg-neutral-100 border-l-4 border-blue-400">
					{children}
				</blockquote>
			);
		case 'paragraph':
		default:
			// If there is a type mismatch we will default to a paragraph
			return (
				<p style={style} {...attributes}>
					{children}
				</p>
			);
	}
};

interface LeafProps {
	attributes: any;
	children: React.ReactNode;
	leaf: any; // Add appropriate type
}

interface RichEditorProps {
	editor: BaseEditor & ReactEditor;
	classes?: string;
}

// Leaves are character-level format options
const LeafX: React.FC<LeafProps> = ({ attributes, children, leaf }) => {
	return (
		//
		<span
			{...attributes}
			className={`
				${leaf.bold ? 'font-bold' : 'font-normal'}
				${leaf.underline ? 'underline' : 'no-underline'}
				${leaf.italic ? 'italic' : 'not-italic'}`}
		>
			{leaf.code ? <code className={`code ${leaf.bold ? 'font-bold' : 'font-normal'}`}>{children}</code> : children}
		</span>
	);
};

// Define our own custom set of helpers.
const RichEditor = {
	exm() {},

	drawToolbar: ({ editor, classes }: RichEditorProps) => (
		<div className="rich-toolbar">
			{/* Indicate which block and marks are active by slightly changing the color of the icon */}
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.toggleBlock(editor, 'h1');
				}}
			>
				<BsTypeH1 />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.toggleBlock(editor, 'h2');
				}}
			>
				<BsTypeH2 />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.toggleBlock(editor, 'h3');
				}}
			>
				<BsTypeH3 />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.toggleBlock(editor, 'h4');
				}}
			>
				<BsTypeH4 />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.toggleBlock(editor, 'blockquote');
				}}
			>
				<BsQuote />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.toggleMark(editor, 'bold');
				}}
			>
				<BsTypeBold />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.toggleMark(editor, 'underline');
				}}
			>
				<BsTypeUnderline />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.toggleMark(editor, 'italic');
				}}
			>
				<BsTypeItalic />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.toggleMark(editor, 'code');
				}}
			>
				<BsCodeSlash />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.changeAlignment(editor, 'left');
				}}
			>
				<BsTextLeft />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.changeAlignment(editor, 'center');
				}}
			>
				<BsTextCenter />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.changeAlignment(editor, 'right');
				}}
			>
				<BsTextRight />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.changeAlignment(editor, 'justify');
				}}
			>
				<BsJustify />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.changeAlignment(editor, 'left');
				}}
			>
				<BsListOl />
			</button>
			<button
				type="button"
				className={`no-btn ${classes ?? ''}`}
				onMouseDown={(event) => {
					event.preventDefault();
					RichEditor.changeAlignment(editor, 'left');
				}}
			>
				<BsListUl />
			</button>
		</div>
	),

	drawEditor: ({ editor, classes }: RichEditorProps) => (
		<>
			<RichEditor.drawToolbar editor={editor} />
			<Editable
				id="slate_message"
				className={`rich-editor ${classes ?? ''}`}
				renderElement={(props) => <ElementX {...props} />}
				renderLeaf={(props) => <LeafX {...props} />}
				// onKeyUp={(e) => {
				// 	handleOnChange(e, ['message'], setMailData);
				// }}
				onKeyDown={(event) => {
					if (!event.ctrlKey) {
						return;
					}

					// Replace the `onKeyDown` logic with our new commands.
					switch (event.key) {
						case '`': {
							event.preventDefault();
							RichEditor.toggleBlock(editor, 'code');
							break;
						}
						case 'b': {
							event.preventDefault();
							RichEditor.toggleMark(editor, 'bold');
							break;
						}
						case 'c': {
							event.preventDefault();
							RichEditor.toggleMark(editor, 'code');
							break;
						}
						case 'u': {
							event.preventDefault();
							RichEditor.toggleMark(editor, 'underline');
							break;
						}
					}
				}}
			/>
		</>
	),

	isMarkActive(editor: BaseEditor & ReactEditor, mark: string): boolean {
		const marks = Editor.marks(editor) as Record<string, any>;
		return marks && mark in marks ? marks[mark] === true : false;
	},

	isBlockActive(editor: BaseEditor & ReactEditor, mark: string): boolean {
		const [match] = Editor.nodes(editor, {
			match: (n: any) => n.type === mark,
		});

		return !!match;
	},

	getAlignment(editor: BaseEditor & ReactEditor, mark: string): boolean {
		const [match] = Editor.nodes(editor, {
			match: (n: any) => n.align === mark,
		});

		return !!match;
	},

	toggleMark(editor: BaseEditor & ReactEditor, mark: string) {
		const isActive = RichEditor.isMarkActive(editor, mark);
		if (isActive) {
			Editor.removeMark(editor, mark);
		} else {
			Editor.addMark(editor, mark, true);
		}
	},

	// TODO - Not doing multiline selection/unselection correctly
	toggleBlock(editor: BaseEditor & ReactEditor, mark: string) {
		const isActive = RichEditor.isBlockActive(editor, mark);
		Transforms.setNodes(
			editor,
			{ type: isActive ? 'paragraph' : mark },
			{ match: (n: any) => Element.isElement(n) && Editor.isBlock(editor, n) }
		);
	},

	// TODO - Not doing multiline selection/unselection correctly
	changeAlignment(editor: BaseEditor & ReactEditor, mark: string) {
		const isActive = RichEditor.getAlignment(editor, mark);
		Transforms.setNodes(
			editor,
			{ align: isActive ? 'unset' : mark },
			{ match: (n: any) => Element.isElement(n) && Editor.isBlock(editor, n) }
		);
	},
};

export default RichEditor;
