import { Contact } from '../../api/Contacts';
import { CONTACT_BUSINESS, CONTACT_PERSON, STATE_MANAGE } from '../../functions/Constants';
import { update } from '../../state/viewSlice';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';

export const toastUnknownError = () => {
	return (
		<div className=" flex gap-2 bg-red-300 text-red-500 p-4 rounded-lg shadow-lg">
			<div></div>
			<div>
				<p>Error</p>
				<p>An unknown error occured.</p>
				<p>If this continues, please contact us.</p>
			</div>
		</div>
	);
};
export const toastServerError = () => {
	return (
		<div className=" flex gap-2 bg-red-300 text-red-500 p-4 rounded-lg shadow-lg">
			<div></div>
			<div>
				<p>Server Error</p>
				<p>The server has provided an invalid response.</p>
				<p>If this continues, please contact us.</p>
			</div>
		</div>
	);
};
export const toastPutSuccessName = (name: string | Contact) => {
	let string;
	if (typeof name === 'object') {
		if (name && name.type === CONTACT_PERSON) {
			string = 'name' in name && name.name?.first + ' ' + name.name?.last;
		} else if (name && name.type === CONTACT_BUSINESS) {
			string = 'title' in name && name.title;
		}
	}

	return (
		<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
			<div></div>
			<div>
				<p>{string} updated!</p>
			</div>
		</div>
	);
};
export const toastPostSuccessName = (name: string | Contact) => {
	let string;
	if (typeof name === 'object') {
		if (name && name.type === CONTACT_PERSON) {
			string = 'name' in name && name.name?.first + ' ' + name.name?.last;
		} else if (name && name.type === CONTACT_BUSINESS) {
			string = 'title' in name && name.title;
		}
	}

	return (
		<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
			<div></div>
			<div>
				<p>{string} added!</p>
			</div>
		</div>
	);
};
export const toastPostSuccessManageLink = (contact: Contact, dispatch: Dispatch<UnknownAction>) => {
	let string;
	if (typeof contact === 'object') {
		if (contact && contact.type === CONTACT_PERSON) {
			string = 'name' in contact && contact.name?.first + ' ' + contact.name?.last;
		} else if (contact && contact.type === CONTACT_BUSINESS) {
			string = 'title' in contact && contact.title;
		}
	}

	return (
		<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
			<div></div>
			<div>
				<p>{string} has been added!</p>
				<input
					type="button"
					className="w-auto"
					onClick={() => dispatch(update({ state: STATE_MANAGE, type: contact.type, _id: contact._id }))}
					value="Manage Contact"
				/>
			</div>
		</div>
	);
};
