import { IconType } from 'react-icons';

interface Props {
	text: string;
	style?: string; // style?: "primary" | "danger" | "info";
	id?: string;
	Icon?: IconType;
	action: () => void;
}

const Button = ({ action, text, style = 'primary', Icon, id }: Props) => {
	return (
		<button
			type="button"
			className={style ? ' p-1 bg-blue-400 hover:bg-blue-300 rounded text-white btn-' + style : 'btn'}
			onClick={() => {
				action();
			}}
		>
			{Icon && <Icon style={{ marginRight: '8px' }} />}
			{text}
		</button>
	);
};

export default Button;
