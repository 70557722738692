// TODO - try and combine this with handleOnChange();
// we can update an input value and use the input onChange event to sent to handleOnChange???

const handleRadioChange = (
	e: React.ChangeEvent<HTMLInputElement>,
	fieldPath: string[],
	setData: React.Dispatch<React.SetStateAction<any>>
) => {
	const value = e.target.value;
	setData((prevData: any) => {
		let updatedData = { ...prevData };
		let currentLevel: any = updatedData;

		// Traverse the nested properties and update the value
		for (let i = 0; i < fieldPath.length - 1; i++) {
			const currentFieldName = fieldPath[i];
			currentLevel[currentFieldName] = { ...currentLevel[currentFieldName] };
			currentLevel = currentLevel[currentFieldName];
		}

		currentLevel[fieldPath[fieldPath.length - 1]] = Number(value);

		return updatedData;
	});
};

export default handleRadioChange;
