const CardContacts = () => {
	return (
		<>
			<div className=" w-auto p-2 glass relative py-3 px-12">
				<div className="grid grid-flow-col grid-cols-2">
					<div>
						<p className="text-center mb-2">Businesses</p>
						<div className="contact-relationship contact-relationship-default mt-2 mb-2 align-middle">
							<span className="text-center align-middle">5</span>&nbsp;No Relationship
						</div>
						<div className="contact-relationship contact-relationship-lead mt-2 mb-2 align-middle">
							<span className="text-center align-middle">3</span>&nbsp;Lead
						</div>
						<div className="contact-relationship contact-relationship-customer mt-2 mb-2 align-middle">
							<span className="text-center align-middle">0</span>&nbsp;Customer
						</div>
						<div className="contact-relationship contact-relationship-connection mt-2 mb-2 align-middle">
							<span className="text-center align-middle">0</span>&nbsp;Connection
						</div>
					</div>
					<div>
						<p className="text-center mb-2">Contacts</p>
						<div className="contact-relationship contact-relationship-default mt-2 mb-2 align-middle">
							<span className="text-center align-middle">12</span>&nbsp;No Relationship
						</div>
						<div className="contact-relationship contact-relationship-lead mt-2 mb-2 align-middle">
							<span className="text-center align-middle">12</span>&nbsp;Lead
						</div>
						<div className="contact-relationship contact-relationship-customer mt-2 mb-2 align-middle">
							<span className="text-center align-middle">12</span>&nbsp;Customer
						</div>
						<div className="contact-relationship contact-relationship-connection mt-2 mb-2 align-middle">
							<span className="text-center align-middle">12</span>&nbsp;Connection
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CardContacts;
