const CardProjects = () => {
	return (
		<>
			<div className=" w-auto p-2 glass relative py-3 px-12">
				<p className="text-center mb-2">Projects</p>
				<div className="grid grid-flow-col grid-cols-2">
					<div>
						<p className="text-center mb-2">Status</p>
						<div className="contact-relationship contact-relationship-default mt-2 mb-2 align-middle">
							<span className="text-center align-middle">5</span>&nbsp;Won't Do
						</div>
						<div className="contact-relationship contact-relationship-lead mt-2 mb-2 align-middle">
							<span className="text-center align-middle">3</span>&nbsp;Not Started
						</div>
						<div className="contact-relationship contact-relationship-customer mt-2 mb-2 align-middle">
							<span className="text-center align-middle">0</span>&nbsp;In Progress
						</div>
						<div className="contact-relationship contact-relationship-connection mt-2 mb-2 align-middle">
							<span className="text-center align-middle">0</span>&nbsp;Stuck
						</div>
						<div className="contact-relationship contact-relationship-connection mt-2 mb-2 align-middle">
							<span className="text-center align-middle">0</span>&nbsp;Paused
						</div>
						<div className="contact-relationship contact-relationship-connection mt-2 mb-2 align-middle">
							<span className="text-center align-middle">0</span>&nbsp;Under Review
						</div>
						<div className="contact-relationship contact-relationship-connection mt-2 mb-2 align-middle">
							<span className="text-center align-middle">0</span>&nbsp;Completed
						</div>
					</div>
					<div>
						<p className="text-center mb-2">Priority</p>
						<div className="contact-relationship contact-relationship-default mt-2 mb-2 align-middle">
							<span className="text-center align-middle">12</span>&nbsp;None
						</div>
						<div className="contact-relationship contact-relationship-lead mt-2 mb-2 align-middle">
							<span className="text-center align-middle">12</span>&nbsp;Lowest
						</div>
						<div className="contact-relationship contact-relationship-customer mt-2 mb-2 align-middle">
							<span className="text-center align-middle">12</span>&nbsp;Low
						</div>
						<div className="contact-relationship contact-relationship-connection mt-2 mb-2 align-middle">
							<span className="text-center align-middle">12</span>&nbsp;Medium
						</div>
						<div className="contact-relationship contact-relationship-connection mt-2 mb-2 align-middle">
							<span className="text-center align-middle">12</span>&nbsp;High
						</div>
						<div className="contact-relationship contact-relationship-connection mt-2 mb-2 align-middle">
							<span className="text-center align-middle">12</span>&nbsp;Highest
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CardProjects;
