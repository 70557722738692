import axios from 'axios';
import { API_ENDPOINT } from './Constants';
import { SelectOption } from '../components/Select';
import { catchError } from './handleLoginAuth';
import { MailProps } from '../pages/marketing/MailSend';
import Contacts, { Contact } from '../api/Contacts';
import { User } from '../api/Users';

export const debugManual = async (toast: any) => {
	try {
		const response = await axios.post(API_ENDPOINT, JSON.stringify({ route: 'debug' }));
		const toastId = toast.open(
			<div className=" flex gap-2 bg-green-300 text-emerald-600 p-4 rounded-lg shadow-lg">
				<div></div>
				<div>
					<h3>Success!</h3>
					<p>{response.data.message}.</p>
				</div>
			</div>,
			5000
		);
	} catch (error: unknown) {
		catchError(error, toast);
	}
};

export const newMailerObject = async (mailData: MailProps, toast: any) => {
	try {
		await axios.post(API_ENDPOINT + '/mailer', mailData, { withCredentials: true });
		const toastId = toast.open(
			<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
				<div></div>
				<div>
					<p>Created Successfully!</p>
					<p>Mail sent successfully.</p>
				</div>
			</div>,
			5000
		);
	} catch (error: unknown) {
		catchError(error, toast);
	}
};

export const getAllContactsArray = async (
	selected: { _id: string | null; name?: { first: string | null; last?: string | null } }[] | undefined
): Promise<SelectOption[]> => {
	const contactList: Contact[] = await Contacts.list.current();

	const options: SelectOption[] = contactList.map((contact: Contact) => ({
		label: `${
			contact && 'name' in contact && contact.name && 'first' in contact.name
				? contact.name.first
				: contact && 'title' in contact && contact.title
					? contact.title
					: ''
		} ${contact && 'name' in contact && contact.name && 'last' in contact.name ? contact.name.last : ''}`,
		_id: contact._id ? contact._id : '',
	}));

	return new Promise<SelectOption[]>((resolve) => {
		resolve(options);
	});
};

export const getAllUsersArray = async (
	selected: { _id: string | null; name?: { first: string | null; last?: string | null } }[] | undefined
): Promise<SelectOption[]> => {
	const contactList: Contact[] = await getAllUsersSelect();

	const options: SelectOption[] = contactList.map((contact: Contact) => ({
		label: `${
			contact && 'name' in contact && contact.name && 'first' in contact.name
				? contact.name.first
				: contact && 'title' in contact && contact.title
					? contact.title
					: ''
		} ${contact && 'name' in contact && contact.name && 'last' in contact.name ? contact.name.last : ''}`,
		_id: contact._id ? contact._id : '',
	}));

	return new Promise<SelectOption[]>((resolve) => {
		resolve(options);
	});
};

export const displayAllContactsSelect = async (
	selected:
		| {
				_id: string | null;
				name: {
					first: string | null;
					last: string | null;
				};
		  }[]
		| undefined
) => {
	const contactList: Contact[] = await Contacts.list.current();

	// TODO - Show contacts and businesses different incase they have the same name
	// TODO - Allow multiple to be chosen in a nice UI
	return (
		<>
			{contactList &&
				contactList.map((contact: Contact, index: number) => (
					<option key={index} value={contact._id} selected={selected?.some((s) => s._id === contact._id)}>
						{`${
							contact && 'name' in contact && contact.name && 'first' in contact.name
								? contact.name.first
								: contact && 'title' in contact && contact.title
									? contact.title
									: ''
						} ${contact && 'name' in contact && contact.name && 'last' in contact.name ? contact.name.last : ''}`}
					</option>
				))}
		</>
	);
};

export const getAllUsersSelect = async () => {
	try {
		const response = await axios.get(API_ENDPOINT + '/user/list', { withCredentials: true });
		console.log('Get user list result: ' + response.data);

		if (response.data) {
			return response.data;
		}
	} catch (error: unknown) {
		catchError(error);
		return [];
	}
};

export const displayAllUsersSelect = async (
	selected:
		| {
				_id: string | null;
				name: {
					first: string | null;
					last: string | null;
				};
		  }[]
		| undefined
) => {
	const userList: User[] = await getAllUsersSelect();

	// TODO - Show contacts and businesses different incase they have the same name
	// TODO - Allow multiple to be chosen in a nice UI
	return (
		<>
			{userList &&
				userList.map((user: User, index: number) => (
					<option key={index} value={user._id} selected={selected?.some((s) => s._id === user._id)}>
						{`${
							user && 'name' in user && user.name && 'first' in user.name
								? user.name.first
								: user && 'title' in user && user.title
									? user.title
									: ''
						} ${user && 'name' in user && user.name && 'last' in user.name ? user.name.last : ''}`}
					</option>
				))}
		</>
	);
};

// export const displayAllUsersSelect = async (
// 	selected:
// 		| {
// 				_id: string | null;
// 				name?: {
// 					first: string | null;
// 					last?: string | null;
// 				};
// 		  }[]
// 		| undefined,
// 	toast: any
// ) => {
// 	const userList: User[] = await getAllUsersSelect(toast);

// 	return (
// 		<>
// 			{userList &&
// 				userList.map((user: User, index: number) => (
// 					<option key={index} value={user._id} selected={selected?.some((s) => s._id === user._id)}>
// 						{`${user.name?.first || ''} ${user.name?.last || ''}`}
// 					</option>
// 				))}
// 		</>
// 	);
// };

// Check if a cookie is set
export const isCookieSet = (cookieName: string) => {
	const cookies = document.cookie.split(';');
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i].trim();

		// Check if the cookie name matches the provided name
		if (cookie.startsWith(`${cookieName}=`)) {
			return true; // Cookie is set
		}
	}
	return false;
};
