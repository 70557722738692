export default () => {
	return (
		<>
			<div className="flex flex-wrap flex-row">
				<div className="w-1/2 p-3">
					<label>Working status</label>
					<select>
						<option>Working from Home</option>
						<option>Working from Office</option>
						<option>Out sick</option>
						<option>On a break</option>
						<option>On Holiday</option>
						<option>Mobile working</option>
						<option>Do not distrub</option>
					</select>
				</div>
				<div className="p-3">
					<label>Email signature</label>
					<br />
					{/* <input type="time" /> */}
					<label>Monday</label>
					<textarea placeholder="- Drae"></textarea>
				</div>
				<div className="p-3">
					<label>Avaliability</label>
					<br />
					{/* <input type="time" /> */}
					<label>Monday</label>
					<input type="time" value="09:00" />
					<input type="time" value="17:00" />
					<label>Tuesday</label>
					<input type="time" value="09:00" />
					<input type="time" value="17:00" onChange={(e) => console.log(e.target.value)} />
				</div>
			</div>
		</>
	);
};
