import { useEffect, useState } from 'react';
import { API_ENDPOINT, Project, IndexedArray, STATE_EDIT } from '../../functions/Constants';
import { BsFillTrash3Fill, BsGrid1X2Fill, BsPencilSquare, BsThreeDots } from 'react-icons/bs';
import axios from 'axios';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { displayProjectPriority, displayProjectStatus } from '../../functions/commonProject';
import { SelectOption } from '../../components/Select';
import Dropdown from '../../components/Dropdown';

export interface ProjectTableProps {
	action: (state: number, id: string) => void;
}

const ProjectTable: React.FC<ProjectTableProps> = ({ action }) => {
	const [tableItems, setTableItems] = useState<number>(0);
	const [projectTableData, setProjectTableData] = useState<Project[]>([]);
	const [showModal, setShowModal] = useState<boolean>(false);

	const relationshipColors: IndexedArray = {
		0: 'default',
		1: 'lead',
		2: 'customer',
		3: 'connection',
	};
	const handleProjectTableCallback = (data: any) => {
		if (data?.length > 0) {
			setTableItems(data.length);
			setProjectTableData(data);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(API_ENDPOINT + '/project', { withCredentials: true });
				handleProjectTableCallback(response.data);
			} catch (error) {
				// TODO - log out the error
			}
		};

		fetchData();
	}, []);

	const deleteProject = async (id: string) => {
		try {
			await axios.delete(`${API_ENDPOINT}/project/${id}`, { withCredentials: true });
			setProjectTableData(projectTableData.filter((project) => project._id !== id));
		} catch (error) {
			console.error('Error deleting contact:', error);
		}
	};

	// const deleteContact = async (id: string) => {
	// 	try {
	// 		await axios.delete(`${API_ENDPOINT}/contact/${id}`, { withCredentials: true });
	// 		setContactTableData(contactTableData.filter((contact) => contact._id !== id));
	// 	} catch (error) {
	// 		console.error('Error deleting contact:', error);
	// 	}
	// };

	return (
		<>
			<div className="clear-both"></div>
			<h3>Project Manager</h3>
			{/* <div className="float-left">
				<span>TODO: Show only projects, show only Businesses</span>
				<br />
				<span>Change Alerts to have an optional icon field and map icons to that field.</span>
				<br />
				<span>Change state to STATE_EDIT after a project has been created with STATE_ADD to update the details instead of making duplicates</span>
				<br />
				<span>add glass morphism to nav menu</span>
				<br />
				<span>
					When a table row is hovered sow a different rainbow background colour for each row so if the mouse moves and the colour <br />
					changes you can easily go back to the colour you wanted to highlight
				</span>
				<br />
				<span>Have a preview button when using smart fields anywehere so the user can see the final result (correct pronouns or typo in smratfield)</span>
			</div>
			{<ProjectManagerKey />} */}
			<div className="project-table container bg-stone-50 dark:bg-dark-600 rounded outline outline-2 outline-neutral-100 dark:outline-dark-800 mt-4">
				<div className=" m-3">
					<p className="float-left">Showing {tableItems} Projects</p>
					<Button action={() => setShowModal(true)} style="primary float-right" text="Add Project"></Button>
				</div>
				<div className="clear-both clear"></div>
				<table className=" table w-full text-left">
					<thead className="table-group-divider">
						<tr className=" bg-slate-200 dark:bg-dark-800">
							<th scope="col" className=" px-3">
								&nbsp;
							</th>
							<th scope="col" className=" px-3">
								Ttile
							</th>
							<th scope="col" className=" px-3">
								Status
							</th>
							<th scope="col" className=" px-3">
								Contact
							</th>
							<th scope="col" className=" px-3">
								Assignee
							</th>
							<th scope="col" className=" px-3">
								Due
							</th>
							<th scope="col" className=" px-3">
								Priority
							</th>
						</tr>
					</thead>
					<tbody className="table-group-divider">
						{/* Project rows */}
						{projectTableData &&
							projectTableData.map((project: Project, index: number) => (
								<tr key={index} className="">
									{/* Action column */}
									<td>
										{/* <button onClick={() => action(STATE_EDIT, contact.id !== undefined ? contact.id : 0)} className="btn btn-link">Edit</button> */}
										<div className="btn-group">
											{/* <button type="button" className="btn-svg min-w-min" data-bs-toggle="dropdown" aria-expanded="false">
											<BsThreeDots />
											<span className=" hidden">Toggle Dropdown</span>
										</button> */}
											<input type="checkbox" className=" mr-2" />
											<Dropdown
												className="left-0"
												object={[
													{
														group: [
															{
																name: 'Edit',
																// description: 'Update contact details',
																func: () => action(STATE_EDIT, project && project._id ? project._id : ''),
																icon: <BsPencilSquare size={32} className=" text-emerald-500" />,
															},
															{
																name: 'Manage',
																// description: 'Create invoices, send emails, view projects',
																func: () => action(STATE_EDIT, project && project._id ? project._id : ''),
																icon: <BsGrid1X2Fill size={32} className=" text-blue-500" />,
															},
														],
													},
													{
														group: [
															{
																name: 'Delete',
																// description: 'Move this project to the bin',
																func: () => deleteProject(project && project._id ? project._id : ''),
																icon: <BsFillTrash3Fill size={32} className=" text-red-500" />,
															},
														],
													},
												]}
											>
												{/* <button type="button" className="btn-svg min-w-min"> */}
												<BsThreeDots />
												{/* </button> */}
											</Dropdown>
										</div>
										{/* <button onClick={() => action(STATE_EDIT, project.id !== undefined ? project.id : 0)} className="btn btn-link">Edit</button>
										<div className="btn-group w-5">
											<Dialog
												object={[
													{
														name: 'Edit',
														description: 'Edit this project',
														func: () =>
															action(
																STATE_EDIT,
																project._id !== undefined && project._id !== null ? project._id : ''
															),
														icon: <BsThreeDots />,
													},
													{
														name: 'Manage',
														description: 'Manage tasks, invoices and send emails',
														func: () =>
															action(
																STATE_EDIT,
																project._id !== undefined && project._id !== null ? project._id : ''
															),
														icon: <BsThreeDots />,
													},
												]}
											>
												<BsThreeDots />
											</Dialog>
										</div> */}
									</td>
									{/* Project Title */}
									<td className="align-middle">&nbsp;{project.title}</td>
									{/* Project Status */}
									<td className="align-middle relative">{displayProjectStatus(project.status)}</td>
									{/* Contacts */}
									<td>
										{project.assigned?.contacts?.map((contact: SelectOption, index: number) => (
											<p key={index}>{contact && 'label' in contact && contact.label ? contact.label : ''}</p>
										))}
									</td>
									{/* <td>{project.assigned?.contacts?.name?.first + ' ' + project.assigned?.contacts?.name?.last}</td> */}
									{/* Assignee */}
									<td>
										{project.assigned?.users &&
											project.assigned.users.map((user: SelectOption, index: number) => (
												<p key={index}>{user && 'label' in user && user.label ? user.label : ''}</p>
											))}
									</td>
									{/* <td>{project.assigned?.users?.name?.first + ' ' + project.assigned?.users?.name?.last}</td> */}
									{/* Due */}
									<td>{'due' in project && project.due && new Date(project.due).toLocaleString('en-AU')}</td>
									{/* Priority */}
									<td className="align-middle relative">{displayProjectPriority(project.priority)}</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
			<Modal show={showModal} title="Add a new Project" onClose={() => setShowModal(false)} btn1={() => setShowModal(false)}>
				<p>Which project would you like to create?</p>
				<button className="btn btn-primary" onClick={() => action(STATE_EDIT, '')}>
					Project
				</button>
				&nbsp;
			</Modal>
		</>
	);
};

export default ProjectTable;

const ProjectManagerKey = () => {
	return (
		<>
			<div className="project-manager-key key float-right">
				<div>
					Icon colors represent your relationship with the person/business
					<ul>
						<li className="project-relationship project-relationship-default">No Relationship</li>
						<li className="project-relationship project-relationship-lead">Lead</li>
						<li className="project-relationship project-relationship-customer">Customer</li>
						<li className="project-relationship project-relationship-connection">Connection</li>
					</ul>
				</div>
			</div>
			<div style={{ clear: 'both' }}></div>
		</>
	);
};
