import React from 'react';
import {
	BsBoxArrowInLeft,
	BsFillPersonVcardFill,
	BsGearWideConnected,
	BsHouseDoorFill,
	BsJournalText,
	BsMegaphoneFill,
} from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import Auth from '../api/Auth';

interface SideBarIconProps {
	to: string;
	icon: React.ReactNode;
	tooltip?: string;
	className?: string;
}

const SideBar = () => {
	const location = useLocation();

	const isActive = (path: string) => {
		return location.pathname === path ? 'active' : '';
	};

	var iconSize = 40;
	return (
		<div className="fixed top-0 h-screen w-24 flex flex-col bg-neutral-50 text-gray-600 shadow-lg dark:bg-dark-900">
			<SideBarIcon
				to="/"
				tooltip={'Home'}
				icon={<BsHouseDoorFill size={iconSize} />}
				className={`${isActive('/')} text-amber-500 hover:bg-amber-500 bg-[#ffffff33]`}
			/>
			<SideBarIcon
				to="/contacts"
				tooltip={'Contacts'}
				icon={<BsFillPersonVcardFill size={iconSize} />}
				className={`${isActive('/contacts')} bg-[#ffffff33]`}
			/>
			<SideBarIcon
				to="/projects"
				tooltip={'Projects'}
				icon={<BsJournalText size={iconSize} />}
				className={`${isActive('/projects')} text-sky-500 hover:bg-sky-500 bg-[#ffffff33]`}
			/>
			<SideBarIcon
				to="/marketing"
				tooltip={'Marketing'}
				icon={<BsMegaphoneFill size={iconSize} />}
				className={`${isActive('/marketing')} text-purple-500 hover:bg-purple-500 bg-[#ffffff33]`}
			/>
			<div className="absolute bottom-0 w-full">
				<Divider />
				<SideBarIcon
					to="/settings"
					tooltip={'Settings'}
					icon={<BsGearWideConnected size={iconSize} />}
					className={`${isActive('/settings')} text-zinc-400 hover:bg-zinc-400`}
				/>
				<button
					className="btn"
					onClick={() => {
						Auth.delete();
					}}
					id="nav-logout"
				>
					<BsBoxArrowInLeft size={25} className={`inline-block my-2`} />
					Logout
				</button>
			</div>
		</div>
	);
};

// Template for icons and tooltips
const SideBarIcon: React.FC<SideBarIconProps> = ({ to, icon, tooltip, className }) => {
	// TODO - RESET STATE back to dashboard so the button still works if you are on that url
	// EG. If you are editing a contact and you click the contact Nav button because you want to go back to the dashboard. (currently doesn't work since url is the same)
	return (
		<>
			<Link to={to} className={`sidebar-icon group class ${className}`}>
				{icon}
				<span className="tooltip m-2 top-20 origin-top group-hover:scale-100">{tooltip}</span>
			</Link>
		</>
	);
};

// Small divider
const Divider = () => <hr className="sidebar-hr" />;

export default SideBar;
