import { User } from '../../../api/Users';
import handleOnChange from '../../../functions/handleOnChange';
import handleRadioChange from '../../../functions/handleRadioChange';

export default ({ dataState }: { dataState: [User, React.Dispatch<React.SetStateAction<User>>] }) => {
	// Use redux to pass data down to components
	const [settingsData, setSettingsData] = dataState;

	return (
		<>
			<div className="flex flex-wrap flex-row">
				<div className=" w-1/4 p-3">
					<label htmlFor="first_name" className="form-label form-control-label-sm required">
						First Name
					</label>
					<input
						type="text"
						defaultValue={settingsData && 'name' in settingsData ? settingsData.name?.first : ''}
						onChange={(e) => {
							handleOnChange(e, ['name', 'first'], setSettingsData);
						}}
						className="form-control form-control-sm"
						id="first_name"
						autoFocus
						required
					/>
				</div>
				<div className="w-1/4 p-3">
					<label htmlFor="middle_name" className="form-label form-control-label-sm">
						Middle Name
					</label>
					<input
						type="text"
						defaultValue={settingsData && 'name' in settingsData ? settingsData.name?.middle : ''}
						onChange={(e) => {
							handleOnChange(e, ['name', 'middle'], setSettingsData);
						}}
						className="form-control form-control-sm"
						id="middle_name"
					/>
				</div>
				<div className="w-1/4 p-3">
					<label htmlFor="last_name" className="form-label form-control-label-sm">
						Last Name
					</label>
					<input
						type="text"
						defaultValue={settingsData && 'name' in settingsData ? settingsData.name?.last : ''}
						onChange={(e) => {
							handleOnChange(e, ['name', 'last'], setSettingsData);
						}}
						className="form-control form-control-sm"
						id="last_name"
					/>
				</div>
				<div className="w-1/4 p-3">
					<label htmlFor="email" className="form-label form-control-label-sm required">
						Email
					</label>
					<input
						type="email"
						defaultValue={settingsData?.email}
						onChange={(e) => {
							handleOnChange(e, ['email'], setSettingsData);
						}}
						className="form-control form-control-sm"
						id="email"
						required
					/>
				</div>
				<div className="w-1/4 p-3">
					<label htmlFor="phone" className="form-label form-control-label-sm">
						Phone
					</label>
					<input
						type="text"
						defaultValue={settingsData?.phone}
						onChange={(e) => {
							handleOnChange(e, ['phone'], setSettingsData);
						}}
						className="form-control form-control-sm"
						id="phone"
					/>
				</div>
				<div className="w-1/4 p-3">
					<label htmlFor="mobile" className="form-label form-control-label-sm">
						Mobile
					</label>
					<input
						type="text"
						defaultValue={settingsData?.mobile}
						onChange={(e) => {
							handleOnChange(e, ['mobile'], setSettingsData);
						}}
						className="form-control form-control-sm"
						id="mobile"
					/>
				</div>
				<div className="w-1/4 p-3">
					<label htmlFor="inputDOB" className="form-label form-control-label-sm">
						Date of Birth
					</label>
					<input
						type="date"
						defaultValue={settingsData && 'dob' in settingsData ? settingsData.dob : ''}
						onChange={(e) => {
							handleOnChange(e, ['dob'], setSettingsData);
						}}
						className="form-control form-control-sm"
						id="inputDOB"
					/>
				</div>
				<div className="w-1/4 p-3">
					<p className="form-label">Gender</p>
					{/* {/* <input type="text" defaultValue={settingsData?.gender} onChange={(e) => {handleOnChange(e, 'gender')}} className="form-control form-control-sm" id="inputGender" placeholder="1234 Main St" /> *, setSettingsData/} */}
					<div className="form-check p-3 inline">
						<input
							className="form-check-input mx-1"
							checked={
								(settingsData && 'gender' in settingsData && settingsData.gender === 0) ||
								(settingsData && 'gender' in settingsData && settingsData.gender === undefined)
							}
							onChange={(e) => {
								handleRadioChange(e, ['gender'], setSettingsData);
							}}
							type="radio"
							name="inputGender"
							id="InputGenderOther"
							defaultValue={0}
						/>
						<label className="form-check-label" htmlFor="InputGenderOther">
							Other
						</label>
					</div>
					<div className="form-check p-3 inline">
						<input
							className="form-check-input mx-1"
							checked={settingsData && 'gender' in settingsData && settingsData.gender === 1}
							onChange={(e) => {
								handleRadioChange(e, ['gender'], setSettingsData);
							}}
							type="radio"
							name="inputGender"
							id="InputGenderMale"
							defaultValue={1}
						/>
						<label className="form-check-label" htmlFor="InputGenderMale">
							Male
						</label>
					</div>
					<div className="form-check p-3 inline">
						<input
							className="form-check-input mx-1"
							checked={settingsData && 'gender' in settingsData && settingsData.gender === 2}
							onChange={(e) => {
								handleRadioChange(e, ['gender'], setSettingsData);
							}}
							type="radio"
							name="inputGender"
							id="InputGenderFemale"
							defaultValue={2}
						/>
						<label className="form-check-label" htmlFor="InputGenderFemale">
							Female
						</label>
					</div>
					{/* <p className="small_help">Used for [THEY_HE_SHE] and [THEM_HIM_HER] smartfields.</p> */}
				</div>
			</div>
			<div className=" w-full flex flex-wrap flex-row">
				{' '}
				{/* Address section */}
				<div className="w-1/5 p-3">
					<label htmlFor="inputAddress" className="form-label form-control-label-sm">
						Country
					</label>
					<input
						type="text"
						defaultValue={settingsData?.address?.country}
						onChange={(e) => {
							handleOnChange(e, ['address', 'country'], setSettingsData);
						}}
						className="form-control form-control-sm"
						id="inputAddress"
						placeholder="Australia"
					/>
				</div>
				<div className="w-1/5 p-3">
					<label htmlFor="inputAddress" className="form-label form-control-label-sm">
						Address
					</label>
					<input
						type="text"
						defaultValue={settingsData?.address?.street}
						onChange={(e) => {
							handleOnChange(e, ['address', 'street'], setSettingsData);
						}}
						className="form-control form-control-sm"
						id="inputAddress"
						placeholder="1234 Main St"
					/>
				</div>
				{/* <div className="w-1/5 p-3">
								<label htmlFor="inputAddress2" className="form-label form-control-label-sm">
									Address 2
								</label>
								<input
									type="text"
									defaultValue={settingsData?.address_2}
									onChange={(e) => {
										handleOnChange(e, 'address_2', setSettingsData);
									}}
									className="form-control form-control-sm"
									id="inputAddress2"
									placeholder="Apartment, studio, or floor"
								/>
							</div> */}
				<div className="w-1/5 p-3">
					<label htmlFor="inputCity" className="form-label form-control-label-sm">
						City
					</label>
					<input
						type="text"
						defaultValue={settingsData?.address?.city}
						onChange={(e) => {
							handleOnChange(e, ['address', 'city'], setSettingsData);
						}}
						className="form-control form-control-sm"
						id="inputCity"
					/>
				</div>
				<div className="w-1/5 p-3">
					<label htmlFor="inputState" className="form-label form-control-label-sm">
						State
					</label>
					<input
						type="text"
						defaultValue={settingsData?.address?.state}
						onChange={(e) => {
							handleOnChange(e, ['address', 'state'], setSettingsData);
						}}
						className="form-control form-control-sm"
						id="inputState"
					/>
					{/* <select id="inputState" className="form-select form-select-sm">
                        <option selected disabled defaultValue={0}>Choose...</option>
                        <option defaultValue={0}>ACT</option>
                        <option defaultValue={1}>NSW</option>
                        <option defaultValue={2}>NT</option>
                        <option defaultValue={3}>QLD</option>
                        <option defaultValue={4}>SA</option>
                        <option defaultValue={5}>TAS</option>
                        <option defaultValue={6}>VIC</option>
                        <option defaultValue={7}>WA</option>
                        </select> */}
				</div>
				<div className="w-1/5 p-3">
					<label htmlFor="inputZip" className="form-label form-control-label-sm">
						Zipcode
					</label>
					<input
						type="text"
						defaultValue={settingsData?.address?.postcode}
						onChange={(e) => {
							handleOnChange(e, ['address', 'postcode'], setSettingsData);
						}}
						className="form-control form-control-sm"
						id="inputZip"
					/>
				</div>
				<p>Allow multiple contact details to be added (emails, phone numbers, etc)</p>
			</div>
		</>
	);
};
