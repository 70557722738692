import CardContacts from './components/CardContacts';
import CardProjects from './components/CardProjects';

const Home: React.FC = () => {
	return (
		<>
			<h3>Home Page</h3>
			<div className=" w-full">
				<div className=" home-contact-stats grid grid-cols-3 gap-8">
					<CardContacts />
					<CardProjects />
				</div>
			</div>
		</>
	);
};

export default Home;
