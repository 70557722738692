import { useCallback, useMemo, useState } from 'react';
import handleOnChange from '../../../functions/handleOnChange';
import { useToast } from '../../../components/toast/ToastService';
import Contacts, { Contact } from '../../../api/Contacts';
import { update } from '../../../state/viewSlice';
import { BsFillTrash3Fill } from 'react-icons/bs';
import { catchError } from '../../../functions/handleLoginAuth';

// export interface ContactNote {
// 	_id: string;
// 	note: Note;
// }

export interface ContactNote {
	_id: string;
	title: string;
	body: string;
}
interface EditNoteProps {
	data: ContactNote;
	setData: (newData: ContactNote) => void;
	handleSubmit: () => void;
	handleDelete: () => void;
	submitLock: boolean;
	onCancel: () => void;
}

export default ({ dataState }: { dataState: [Contact, React.Dispatch<React.SetStateAction<Contact>>] }) => {
	// Extract state values
	const [contact, setContact] = dataState;
	const [editingNote, setEditingNote] = useState<ContactNote | null>(null); // Track currently edited note
	const [data, setData] = useState<ContactNote>({ _id: '', title: '', body: '' });
	const [submitLock, setSubmitLock] = useState<boolean>(false);
	const toast = useToast();

	const handleSubmit = useCallback(async () => {
		setSubmitLock(true);
		if (data.title === '' && data.body === '') {
			const toastId = toast.open(
				<div className=" flex gap-2 bg-red-300 text-red-500 p-4 rounded-lg shadow-lg">
					<div></div>
					<div>
						<p>Missing Fields!</p>
						<p>Please complete all required fields.</p>
					</div>
				</div>,
				5000
			);
		} else {
			const updatedNote = { ...data }; // Create a copy to avoid mutation
			if (editingNote && data._id) {
				updatedNote._id = editingNote._id;
				const response = await Contacts.notes.put(contact._id, updatedNote);
				if (response._id !== '') {
					// TODO - When a note is created, flip around to show the 'note card'
					if (contact.notes) {
						const noteIndex = contact.notes.findIndex((note) => note._id === response._id);

						// Replace the old note with the updated note
						if (noteIndex !== -1) {
							contact.notes[noteIndex] = response;
						}
					} else {
						contact.notes = [response];
					}
				}
				setEditingNote(null); // Clear buffer
				setData({ _id: '', title: '', body: '' }); // Clear buffer
				const toastId = toast.open(
					<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
						<div></div>
						<div>
							<p>Note updated!</p>
						</div>
					</div>,
					8000
				);
			} else {
				const response = await Contacts.notes.post(contact._id, data);
				if (response._id !== '') {
					// TODO - When a note is created, flip around to show the 'note card'
					if (contact.notes) {
						contact.notes = [...contact.notes, response];
					} else {
						contact.notes = [response];
					}
				}
				setData({ _id: '', title: '', body: '' }); // Clear buffer
				const toastId = toast.open(
					<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
						<div></div>
						<div>
							<p>Note added!</p>
						</div>
					</div>,
					8000
				);
			}
		}
		setSubmitLock(false);
	}, [data, editingNote, contact._id]);

	const processEditNote = useCallback((note: ContactNote) => {
		setEditingNote(note);
		setData({ ...note }); // Pre-populate edit form with note data
	}, []);

	const cancelEdit = useCallback(() => {
		setEditingNote(null);
		setData({ _id: '', title: '', body: '' }); // Reset edit state
	}, []);

	const handleDelete = useCallback(() => {
		const updatedNote = { ...data }; // Create a copy to avoid mutation
		if (editingNote && updatedNote) {
			updatedNote._id = editingNote._id;
			try {
				Contacts.notes.delete(contact._id, data, toast);
			} catch (error) {
				catchError(error);
			}
		} else {
			const toastId = toast.open(
				<div className=" flex gap-2 bg-red-300 text-emerald-500 p-4 rounded-lg shadow-lg">
					<div></div>
					<div>
						<p>Error!</p>
						<p>Please try again.</p>
					</div>
				</div>,
				8000
			);
		}
	}, [data, editingNote, contact._id]);

	// const handleSubmitWrapper = useCallback(
	// 	(e: React.FormEvent<HTMLFormElement>) => {
	// 		handleSubmit(
	// 			e,
	// 			[contact, setContact],
	// 			{
	// 				postbyId: Contacts.notes.post(contact._id, toast),
	// 				putById: Contacts.notes.put(contact._id, toast),
	// 			},
	// 			setSubmitLock,
	// 			toast
	// 		);
	// 	},
	// 	[contact, setContact, setSubmitLock, toast]
	// );

	// const editNote = (contactId: string, _id?: string) => {
	// 	return (
	// 		<div className="relative flex flex-wrap flex-col w-auto px-4 py-4 bg-amber-50 border-amber-100 border-2">
	// 			<div className=" flex flex-col">
	// 				<label htmlFor="title">Title</label>
	// 				<input
	// 					className=""
	// 					type="text"
	// 					id="title"
	// 					name="title"
	// 					value={data && data.title ? data.title : ''}
	// 					onChange={(e) => {
	// 						handleOnChange(e, ['note', 'title'], setData);
	// 					}}
	// 				/>
	// 			</div>
	// 			<div className="mt-8">
	// 				<label htmlFor="body">Body</label>
	// 				<textarea
	// 					className=""
	// 					id="body"
	// 					name="body"
	// 					rows={6}
	// 					value={data && data.body ? data.body : ''}
	// 					onChange={(e) => {
	// 						handleOnChange(e, ['note', 'body'], setData);
	// 					}}
	// 				></textarea>
	// 				{/* <div className=""> */}
	// 				<ul className=" m-auto p-4 flex gap-4">
	// 					<li className=" bg-red-300 size-6 block rounded-full cursor-pointer border-2 hover:border-slate-300"></li>
	// 					<li className=" bg-pink-300 size-6 block rounded-full cursor-pointer border-2 hover:border-slate-300"></li>
	// 					<li className=" bg-amber-300 size-6 block rounded-full cursor-pointer border-2 hover:border-slate-300 border-slate-400 "></li>
	// 					<li className=" bg-emerald-300 size-6 block rounded-full cursor-pointer border-2 hover:border-slate-300"></li>
	// 					<li className=" bg-sky-300 size-6 block rounded-full cursor-pointer border-2 hover:border-slate-300"></li>
	// 					<li className=" bg-violet-300 size-6 block rounded-full cursor-pointer border-2 hover:border-slate-300"></li>
	// 				</ul>
	// 				{/* </div> */}
	// 				<button type="submit" onClick={handleSubmit} disabled={submitLock}>
	// 					Add
	// 				</button>
	// 			</div>
	// 		</div>
	// 	);
	// };

	return (
		<>
			<div className="grid gap-4 grid-cols-3">
				{/* <div className="flex gap-4 flex-wrap"> */}
				{contact.notes &&
					contact.notes.map((note: any, index: number) => (
						// TODO - Allow note colour
						<div
							key={index} // Use note ID for efficient keying
							className={`relative flex flex-wrap flex-col w-auto min-w-60 px-4 py-4 bg-amber-50 border-amber-100 border-2 cursor-pointer ${
								editingNote === note ? 'editing' : ''
							}`}
							onClick={() => (editingNote && editingNote._id == note._id ? cancelEdit() : processEditNote(note))}
						>
							{/* TODO - When the note is clicked, spin it around revealing the edit settings */}
							{editingNote && editingNote._id === note._id ? ( // Edit note
								<EditNote // Render editNote component when editing
									data={data}
									setData={setData}
									handleSubmit={handleSubmit}
									handleDelete={handleDelete}
									submitLock={submitLock}
									onCancel={cancelEdit} // Added cancel functionality
								/>
							) : (
								// Show Note
								<>
									<p className="text-xl font-bold ">{note.title}</p>
									<p className="pb-12  pre-line whitespace-pre-line">{note.body}</p>
									<div className="absolute bottom-2">
										<span>Created {new Date(note.created.date).toLocaleString('en-AU')} by Drae Ockenden</span>
									</div>
								</>
							)}
						</div>
					))}
				{!editingNote && (
					<div className="relative flex flex-wrap flex-col w-auto px-4 py-4 bg-amber-50 border-amber-100 border-2">
						{/* TODO - maybe show a blank area with a "click to add" type thing that the edit settings can switch positions with */}
						<EditNote // Render editNote component when editing
							data={data}
							setData={setData}
							handleSubmit={handleSubmit}
							handleDelete={handleDelete}
							submitLock={submitLock}
							onCancel={cancelEdit} // Added cancel functionality
						/>
					</div>
				)}
			</div>
		</>
	);
};
const EditNote = ({ data, setData, handleSubmit, handleDelete, submitLock, onCancel }: EditNoteProps) => {
	return (
		<>
			<div className=" flex flex-col">
				<label htmlFor="title">Title</label>
				<input
					className=""
					type="text"
					id="title"
					name="title"
					// autoFocus
					value={data.title}
					onChange={(e) => handleOnChange(e, ['title'], setData)}
					onClick={(e) => e.stopPropagation()} // Prevent parent onClick from firing
				/>
			</div>
			<div className="mt-8">
				<label htmlFor="body">Body</label>
				<textarea
					className=""
					id="body"
					name="body"
					rows={6}
					value={data.body}
					onChange={(e) => setData({ ...data, body: e.target.value })}
					onClick={(e) => e.stopPropagation()} // Prevent parent onClick from firing
				></textarea>
				<div className="">
					<ul className=" m-auto p-4 flex gap-4">
						<li className=" bg-red-300 size-6 block rounded-full cursor-pointer border-2 hover:border-slate-300"></li>
						<li className=" bg-pink-300 size-6 block rounded-full cursor-pointer border-2 hover:border-slate-300"></li>
						<li className=" bg-amber-300 size-6 block rounded-full cursor-pointer border-2 hover:border-slate-300 border-slate-400 "></li>
						<li className=" bg-emerald-300 size-6 block rounded-full cursor-pointer border-2 hover:border-slate-300"></li>
						<li className=" bg-sky-300 size-6 block rounded-full cursor-pointer border-2 hover:border-slate-300"></li>
						<li className=" bg-violet-300 size-6 block rounded-full cursor-pointer border-2 hover:border-slate-300"></li>
					</ul>
				</div>
				<div className="flex justify-end mt-2">
					{data._id && <BsFillTrash3Fill size={24} onClick={handleDelete} />}
					{data._id && (
						<button className="mr-2 bg-red-300 text-white rounded-lg hover:bg-red-400" onClick={onCancel}>
							Cancel
						</button>
					)}
					<button className="bg-green-300 text-white rounded-lg hover:bg-green-400" onClick={handleSubmit} disabled={submitLock}>
						Save
					</button>
				</div>
			</div>
		</>
	);
};
