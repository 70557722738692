import { IconType } from 'react-icons';

interface Props {
	text: string;
	style?: string; // style?: "primary" | "danger" | "info";
	id?: string;
	Icon?: IconType;
	action: () => void;
}
//  setData: React.Dispatch<React.SetStateAction<any>>

const SelectOptionDiv = ({ action, text, style = 'primary', Icon, id }: Props) => {
	return (
		<>
			<button
				type="button"
				className={`absolute inset-0 reset-btn h-full w-full text-white ${style}`}
				onClick={() => {
					action();
				}}
			>
				{Icon && <Icon style={{ marginRight: '8px' }} />}
				{text}
			</button>
			{/* <Dropdown></Dropdown> */}
		</>
	);
};

export default SelectOptionDiv;
