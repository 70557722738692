import SelectOptionDiv from '../components/SelectOptionDiv';

// TODO - add html to make these look better
export const displayProjectStatus = (status: number | null | undefined) => {
	var message, style;
	switch (status) {
		case 0:
			message = "Won't Do";
			style = 'bg-red-500 hover:bg-red-700';
			break;
		case 1 || null || undefined:
			message = 'Not Started';
			style = 'bg-slate-500 hover:bg-slate-700';
			break;
		case 2:
			message = 'In Progress';
			style = 'bg-blue-500 hover:bg-blue-700';
			break;
		case 3:
			message = 'Stuck';
			style = 'bg-yellow-500 hover:bg-yellow-700';
			break;
		case 4:
			message = 'Paused';
			style = 'bg-blue-500 hover:bg-blue-700';
			break;
		case 5:
			message = 'Under Review';
			style = 'bg-amber-500 hover:bg-amber-700';
			break;
		case 10:
			message = 'Completed';
			style = 'bg-emerald-500 hover:bg-emerald-700';
			break;
	}

	return (
		<SelectOptionDiv
			text={message ? message : ''}
			style={style}
			action={function (): void {
				throw new Error('Function not implemented.');
			}}
		/>
	);
};

// TODO - add html to make these look better
export const displayProjectPriority = (priority: number | null | undefined) => {
	var message, style;
	switch (priority) {
		case 0 || null || undefined:
			message = 'None';
			style = 'bg-slate-500 hover:bg-slate-700';
			break;
		case 1:
			message = 'Lowest';
			style = 'bg-indigo-500 hover:bg-indigo-700';
			break;
		case 2:
			message = 'Low';
			style = 'bg-sky-500 hover:bg-sky-700';
			break;
		case 3:
			message = 'Medium';
			style = 'bg-yellow-500 hover:bg-yellow-700';
			break;
		case 4:
			message = 'High';
			style = 'bg-amber-500 hover:bg-amber-700';
			break;
		case 5:
			message = 'Highest';
			style = 'bg-red-500 hover:bg-red-700';
			break;
	}

	return (
		<SelectOptionDiv
			text={message ? message : ''}
			style={style}
			action={function (): void {
				throw new Error('Function not implemented.');
			}}
		/>
	);
};
