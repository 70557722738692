import { useState } from 'react';
import ProjectTable from './table';
import { STATE_ADD, STATE_DASHBOARD, STATE_EDIT, STATE_MANAGE } from '../../functions/Constants';
import ProjectEdit from './edit';
import Alert from '../../components/Alert';

const ContactRouteLanding: React.FC = () => {
	const [currentState, setCurrentState] = useState<number>(0);
	const [currentID, setCurrentID] = useState<string>('');
	const [alertVisible, setAlertVisible] = useState<boolean>(false);
	const [alertStyle, setAlertStyle] = useState<string>('primary');
	const [alertText, setAlertText] = useState<string>('');

	const handleContactAction = (action: number, id: string) => {
		setCurrentState(action);
		setCurrentID(id);
	};

	const handleAlert = (visibility: boolean, style: string, text: string) => {
		setAlertVisible(visibility);
		setAlertStyle(style);
		setAlertText(text);
	};

	return (
		<>
			{alertVisible && (
				<Alert onClose={() => setAlertVisible(false)} style={alertStyle}>
					{alertText}
				</Alert>
			)}
			{currentState === STATE_DASHBOARD && <ProjectTable action={handleContactAction} />}
			{currentState === STATE_ADD && <ProjectEdit _id={currentID} alertProps={handleAlert} action={handleContactAction} />}
			{currentState === STATE_MANAGE && <ProjectEdit _id={currentID} alertProps={handleAlert} action={handleContactAction} />}
			{currentState === STATE_EDIT && <ProjectEdit _id={currentID} alertProps={handleAlert} action={handleContactAction} />}
		</>
	);
};

export default ContactRouteLanding;
