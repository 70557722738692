import { ContactNote } from '../pages/contacts/components/EditNotes';

// DEBUG MODE
export const DEBUG_MODE = true; // prevents emails from being sent

// API
export const API_ENDPOINT = 'https://node.draeockenden.com.au/api';

// Contact Editing
export const STATE_DASHBOARD = 0;
export const STATE_MANAGE = 1;
export const STATE_ADD = 2;
export const STATE_EDIT = 3;

export const CONTACT_PERSON = 1;
export const CONTACT_BUSINESS = 2;

// User settings Editing
export const SETTINGS_STATE_USER = 1;
export const SETTINGS_STATE_ACCOUNT = 2;

// Error codes
export const ERROR_CODE_403 = 'Access to this rescource has been denied.';
export const ERROR_CODE_404 = 'This resource cannot be found.';

export const authResultSuccess = 0;
export const authResultInvalidUsername = 1;
export const authResultInvalidPassword = 2;
export const authResultServerError = 10;

export type DataState<T> = [T, React.Dispatch<React.SetStateAction<T>>];

export interface Account {
	_id: _id;
	title?: string;
	tagline?: string;
	industry?: string;
	website?: string;
	username: string;
	email?: string;
	phone?: string;
	mobile?: string;
	address?: { street?: string; city?: string; postcode?: number; state?: string; country?: string };
}

export interface Project {
	_id?: _id | null;
	title?: string | null;
	description?: string | null;
	priority?: number | null;
	status?: number | null;
	assigned?: {
		contacts?: {
			_id: _id | null;
			label: string | null;
		}[];
		users?: { _id: _id | null; label: string | null }[];
	};
	pricing?: {
		cost: number | null;
		method: number | null;
		direction: number | null;
		interval: number | null;
		units: number | null;
		time: number | null;
		promotion: number | null;
		estimated: { cost: number | null; time: number | null };
	};
	due?: string | null;
	account?: string | null;
	history?: {
		created?: { date: string | null; by: _id | null };
		deleted?: { date: string | null; by: _id | null };
	};
}

export interface ContactEditProps {
	contact_id: _id;
}

export interface BusinessEditProps {
	business_id: _id;
}

export interface ContactTableProps {}

export interface AlertProps {
	response: (visibility: boolean, style: string, text: string) => void;
}

export type IndexedArray = {
	[key: number | string]: string | number | boolean | undefined;
};

// Declare `_id` to avoid confusion with data type
export type _id = string;
