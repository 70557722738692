import { useState } from 'react';
import { SiCivicrm } from 'react-icons/si';
import { BsEye } from 'react-icons/bs';
import Auth from '../api/Auth';

interface Props {
	action: React.Dispatch<React.SetStateAction<boolean>>;
	toast: any;
}

function LoginForm({ action, toast }: Props) {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUsername(event.target.value);
	};
	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		Auth.post({ username: username, password: password }, action, toast);
	};

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					{/* <img className="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company" /> */}
					<SiCivicrm className="mx-auto w-auto" size={75} />
					<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					<form className="space-y-6" action="#" method="post" onSubmit={handleSubmit}>
						<div>
							<label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
								Username
							</label>
							<div className="mt-2">
								<input
									id="username"
									name="login_username"
									type="username"
									autoComplete="username"
									required
									className="block w-full sm:text-sm sm:leading-6"
									onChange={handleUsernameChange}
								/>
							</div>
						</div>

						<div>
							<div className="flex items-center justify-between">
								<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
									Password
								</label>
								<div className="text-sm">
									<a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
										Forgot password?
									</a>
								</div>
							</div>
							<div className="mt-2">
								<input
									id="password"
									name="login_password"
									type="password"
									autoComplete="current-password"
									required
									className="block w-full sm:text-sm sm:leading-6"
									onChange={handlePasswordChange}
								/>
								{/* TODO - Toggle password visibility */}
								<BsEye />
							</div>
						</div>

						<div>
							<button
								type="submit"
								// TODO - disable button while api is responding (both manual login and session based authorization)
								className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Sign in
							</button>
						</div>
					</form>

					<p className="mt-10 text-center text-sm text-gray-500">
						Don't have an account?{' '}
						<a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
							Start a 14 day free trial
						</a>
					</p>
				</div>
			</div>
		</>
	);
}

export default LoginForm;
