import { useEffect, useState } from 'react';
import MailEdit from './MailSend';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../state/store';
import { STATE_ADD, STATE_DASHBOARD } from '../../functions/Constants';
import { update } from '../../state/viewSlice';
// import { Alert } from 'react-bootstrap';

const MarketingRouteLanding: React.FC = () => {
	const [currentState, setCurrentState] = useState<number>(0);
	const [alertVisible, setAlertVisible] = useState<boolean>(false);
	const [alertStyle, setAlertStyle] = useState<string>('primary');
	const [alertText, setAlertText] = useState<string>('');
	const viewState = useSelector((state: RootState) => state.example.value);
	const dispatch = useDispatch();

	useEffect(() => {
		// Reset scene to dashboard
		dispatch(update({ state: STATE_DASHBOARD }));
	}, []);

	// const handleUserAction = (type: number = 0, action: number, id: number) => {
	// 	setCurrentState(action);
	// };

	// const handleAlert = (visibility: boolean, style: string, text: string) => {
	// 	setAlertVisible(visibility);
	// 	setAlertStyle(style);
	// 	setAlertText(text);
	// };

	function renderSwitch() {
		switch (viewState.state) {
			case STATE_ADD:
				return (
					<MailEdit
						_id={''}
						action={function (state: number, id: string): void {
							throw new Error('Function not implemented.');
						}}
						alertProps={function (visibility: boolean, style: string, text: string): void {
							throw new Error('Function not implemented.');
						}}
					/>
				);
			case STATE_DASHBOARD:
			// return <EditWork data={[data, setData]} />;
			default:
			// return <EditDetails data={[data, setData]} />;
		}
	}

	return (
		<>
			<span>
				<BsFillArrowLeftCircleFill size={32} className="text-red-600 rounded-xl" />
			</span>
			<button onClick={() => dispatch(update({ state: STATE_ADD, _id: '' }))}>Send an email</button>
			<div>Marketing</div>
			Table with past emails sent
			{renderSwitch()}
		</>
	);
};

export default MarketingRouteLanding;
