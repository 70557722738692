import axios from 'axios';
import LoginForm from './components/LoginForm.tsx';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ContactRouteLanding from './pages/contacts/index.tsx';
import Home from './pages/home/index.tsx';
import Error404 from './pages/Error404.tsx';
import { API_ENDPOINT } from './functions/Constants.tsx';
import { catchError } from './functions/handleLoginAuth.tsx';
import ProjectRouteLanding from './pages/projects/index.tsx';
import SettingsPage from './pages/settings/index.tsx';
import SideBar from './components/SideBar.tsx';
import MarketingRouteLanding from './pages/marketing/index.tsx';
import { useToast } from './components/toast/ToastService.js';

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
	const toast = useToast();

	// Is the user logged in
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(API_ENDPOINT + '/auth', { withCredentials: true });

				setIsLoggedIn(response.data.result);
				const toastId = toast.open(
					<div className=" flex gap-2 bg-green-300 text-emerald-600 p-4 rounded-lg shadow-lg">
						<div></div>
						<div>
							<p>Session Restored</p>
							<p>{response.data.message}.</p>
						</div>
					</div>,
					5000
				);
			} catch (error: unknown) {
				catchError(error, toast);
			}
		};

		fetchData();
	}, []);

	return (
		<>
			{isLoggedIn ? (
				<>
					{/* Move glass rendering to a function that makes it random and stlyes it */}
					<div className="glass-backdrop">
						<div
							className="glass"
							style={{ width: '105px', height: '123px', top: '6%', left: '81%', transform: 'rotate(45deg)', opacity: '0.6' }}
						></div>
						<div
							className="glass"
							style={{ width: '187px', height: '63px', top: '82%', left: '76%', transform: 'rotate(5deg)', opacity: '0.7' }}
						></div>
						<div
							className="glass"
							style={{ width: '100px', height: '100px', top: '73%', left: '16%', borderRadius: '500px', opacity: '0.7' }}
						></div>
					</div>
					<div className="flex">
						<BrowserRouter>
							{/* <Navbar /> */}
							<SideBar />
							<div
								id="body"
								className=" w-screen h-screen relative p-6 ml-24 dark:bg-dark-700 dark:text-zinc-300 overflow-y-auto overflow-x-hidden"
							>
								<Routes>
									<Route path="/" Component={Home} />
									<Route path="/contacts" Component={ContactRouteLanding} />
									<Route path="/projects" Component={ProjectRouteLanding} />
									<Route path="/marketing" Component={MarketingRouteLanding} />
									<Route path="/settings" Component={SettingsPage} />
									<Route path="*" Component={Error404} />
								</Routes>
							</div>
						</BrowserRouter>
					</div>
				</>
			) : (
				<>
					<div className="login-container">
						<LoginForm action={setIsLoggedIn} toast={toast} />
					</div>
				</>
			)}
		</>
	);
}

export default App;
