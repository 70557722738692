import { useEffect, useRef, useState } from 'react';
import { IndexedArray, Project, STATE_DASHBOARD } from '../../functions/Constants';
import { useToast } from '../../components/toast/ToastService';
import { displayAllUsersSelect, getAllContactsArray, getAllUsersArray } from '../../functions/CommonFunctions';
import handleOnChange from '../../functions/handleOnChange';
import { Select, SelectOption } from '../../components/Select';
import Projects from '../../api/Projects';

export interface ProjectEditProps {
	_id: string;
	action: (state: number, id: string) => void;
	alertProps: (visibility: boolean, style: string, text: string) => void;
}

const ContactEdit: React.FC<ProjectEditProps> = ({ _id, action, alertProps }) => {
	const [data, setData] = useState<Project>({
		_id: null,
		title: null,
		description: null,
		priority: 3,
		status: 1, // Not started
		assigned: {
			contacts: [],
			users: [],
		},
		pricing: {
			cost: null,
			method: null,
			direction: null,
			interval: null,
			units: null,
			time: null,
			promotion: null,
			estimated: {
				cost: null,
				time: null,
			},
		},
		due: null,
	});
	const [submitLock, setSubmitLock] = useState<boolean>(false);
	const [contactsList, setContactsList] = useState<SelectOption[] | undefined>();
	const [usersList, setUsersList] = useState<SelectOption[] | undefined>();
	const pageTitle = useRef('Add a new project');
	const toast = useToast();
	useEffect(() => {
		// setData({
		// 	_id: null,
		// 	title: null,
		// 	description: null,
		// 	priority: 3,
		// 	status: 1, // Not started
		// 	assigned: {
		// 		contacts: [],
		// 		users: [],
		// 	},
		// 	pricing: {
		// 		cost: null,
		// 		method: null,
		// 		direction: null,
		// 		interval: null,
		// 		units: null,
		// 		time: null,
		// 		promotion: null,
		// 		estimated: {
		// 			cost: null,
		// 			time: null,
		// 		},
		// 	},
		// 	due: null,
		// });
		async function fetchObjects() {
			if (_id != '') {
				await Projects.get(_id, setData);
				if (data?.title) {
					pageTitle.current = 'Edit ' + data.title;
				}
			}
			const contactsOptions = await getAllContactsArray(data?.assigned?.contacts);
			setContactsList(contactsOptions);
			// TODO - users list
			const usersOptions = await getAllUsersArray(data?.assigned?.users);
			setUsersList(usersOptions);
		}
		fetchObjects();
	}, [_id]);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		const form = e.currentTarget;
		setSubmitLock(true);
		e.preventDefault();
		if (!form.checkValidity() || data === undefined) {
			const toastId = toast.open(
				<div className=" flex gap-2 bg-red-300 text-red-500 p-4 rounded-lg shadow-lg">
					<div></div>
					<div>
						<p>Missing Fields!</p>
						<p>Please complete all required fields.</p>
					</div>
				</div>,
				5000
			);
		} else {
			if (data._id) {
				await Projects.patch(data);
				const toastId = toast.open(
					<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
						<div></div>
						<div>
							<p>Saved Successfully!</p>
							<p>New contact details saved successfully.</p>
						</div>
					</div>,
					5000
				);
			} else {
				await Projects.post(data);
				const toastId = toast.open(
					<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
						<div></div>
						<div>
							<p>Created Successfully!</p>
							<p>New contact created successfully.</p>
						</div>
					</div>,
					5000
				);
			}
		}
		setSubmitLock(false);
		form.classList.add('was-validated');
	};

	return (
		<div className="">
			{/* <h3>{data?.name?.first ? 'Edit ' + data?.name.first : 'Add a contact'}</h3> */}
			<h3>{pageTitle.current}</h3>
			<div className="flex gap-2 items-stretch">
				<form className=" flex" method="post" noValidate onSubmit={handleSubmit}>
					<fieldset className=" w-3/4">
						<legend>
							<h3 className="">Details</h3>
						</legend>
						<div className=" w-1/3 px-2">
							<label htmlFor="title" className="form-label form-control-label-sm required">
								Title
							</label>
							<input
								type="text"
								value={data?.title || undefined}
								onChange={(e) => {
									handleOnChange(e, ['title'], setData);
								}}
								className="form-control form-control-sm"
								id="title"
								autoFocus
								required
							/>
							<p className="small_help">Used for [PROJECT_TITLE] smartfields. </p>
						</div>
						<div className=" w-1/3 px-2">
							<label htmlFor="contacts" className="form-label form-control-label-sm">
								Contacts
							</label>
							<Select
								multiple
								value={data && 'assigned' in data ? data?.assigned?.contacts : [{ _id: '', label: '' }]}
								onChange={(e) => {
									handleOnChange(e, ['assigned', 'contacts'], setData);
								}}
								options={contactsList}
							/>
						</div>
						<div className=" w-1/3 px-2">
							<label htmlFor="users" className="form-label form-control-label-sm">
								Users
							</label>
							<Select
								multiple
								value={data && 'assigned' in data ? data?.assigned?.users : [{ _id: '', label: '' }]}
								onChange={(e) => {
									handleOnChange(e, ['assigned', 'users'], setData);
								}}
								options={usersList}
							/>
						</div>
						<div className=" w-1/3 px-2">
							<label htmlFor="status" className="form-label form-control-label-sm">
								Status
							</label>
							<select
								id="status"
								className="form-select form-select-sm"
								onChange={(e) => {
									handleOnChange(e, ['status'], setData);
								}}
							>
								<option value={0} selected={data?.status === 0}>
									Won't Do
								</option>
								<option value={1} selected={data?.status === 1 || data?.status === undefined}>
									Not Started
								</option>
								<option value={2} selected={data?.status === 2}>
									In Progress
								</option>
								<option value={3} selected={data?.status === 3}>
									Stuck
								</option>
								<option value={4} selected={data?.status === 4}>
									Paused
								</option>
								<option value={5} selected={data?.status === 5}>
									Under Review
								</option>
								<option value={10} selected={data?.status === 10}>
									Completed
								</option>
							</select>
						</div>
						<div className=" w-1/3 px-2">
							<label htmlFor="priority" className="form-label form-control-label-sm">
								Priority
							</label>
							<select
								id="priority"
								className="form-select form-select-sm"
								onChange={(e) => {
									handleOnChange(e, ['priority'], setData);
								}}
							>
								<option value={0} selected={data?.priority === 0 || data?.priority === undefined}>
									None
								</option>
								<option value={1} selected={data?.priority === 1}>
									Lowest
								</option>
								<option value={2} selected={data?.priority === 2}>
									Low
								</option>
								<option value={3} selected={data?.priority === 3}>
									Medium
								</option>
								<option value={4} selected={data?.priority === 4}>
									High
								</option>
								<option value={5} selected={data?.priority === 5}>
									Highest
								</option>
							</select>
						</div>
						<div className="w-1/3 px-2">
							<label htmlFor="due" className="form-label form-control-label-sm">
								Due Date
							</label>
							<input
								type="datetime-local"
								value={data?.due || undefined}
								onChange={(e) => {
									handleOnChange(e, ['due'], setData);
								}}
								className="form-control form-control-sm"
								id="due"
							/>
						</div>
						{/* <div className="w-1/4 px-2">
							<p className="form-label">Gender</p>
							<div className="form-check px-2 inline">
								<input
									className="form-check-input mx-1"
									checked={data?.gender === 0 || data?.gender === undefined}
									onChange={(e) => {
										handleGenderChange(e);
									}}
									type="radio"
									name="inputGender"
									id="InputGenderOther"
									value={0}
								/>
								<label className="form-check-label" htmlFor="InputGenderOther">
									Other
								</label>
							</div>
							<div className="form-check px-2 inline">
								<input
									className="form-check-input mx-1"
									checked={data?.gender === 1}
									onChange={(e) => {
										handleGenderChange(e);
									}}
									type="radio"
									name="inputGender"
									id="InputGenderMale"
									value={1}
								/>
								<label className="form-check-label" htmlFor="InputGenderMale">
									Male
								</label>
							</div>
							<div className="form-check px-2 inline">
								<input
									className="form-check-input mx-1"
									checked={data?.gender === 2}
									onChange={(e) => {
										handleGenderChange(e);
									}}
									type="radio"
									name="inputGender"
									id="InputGenderFemale"
									value={2}
								/>
								<label className="form-check-label" htmlFor="InputGenderFemale">
									Female
								</label>
							</div>
						</div> */}
						<div className=" w-full px-2">
							<label htmlFor="description" className="form-label form-control-label-sm">
								Description
							</label>
							<textarea
								value={data?.description || undefined}
								rows={8}
								onChange={(e) => {
									handleOnChange(e, ['description'], setData);
								}}
								className="form-control form-control-sm"
								id="description"
							></textarea>
							<p className="small_help">Private notes only users in your company can see.</p>
						</div>
						<button type="submit" className=" bg-emerald-500 hover:bg-emerald-600" disabled={submitLock}>
							{submitLock ? 'Loading...' : data?.title ? 'Save ' : 'Add'}
						</button>
						<button type="submit" className="bg-amber-500 hover:bg-amber-600 w-fit px-4">
							Save & Create a new Business
						</button>
						<button type="button" onClick={() => action(STATE_DASHBOARD, '')} className=" bg-red-500 hover:bg-red-600">
							Back
						</button>
					</fieldset>

					<fieldset className="">
						<legend>
							<h3 className="">Pricing</h3>
						</legend>
						<div className=" w-1/3 px-2">
							<label htmlFor="status" className="form-label form-control-label-sm">
								Method
							</label>
							<select
								id="pricing_method"
								className="form-select form-select-sm"
								onChange={(e) => {
									handleOnChange(e, ['pricing', 'method'], setData);
								}}
							>
								<option value={0} selected={data?.pricing?.method === 0 || data?.pricing?.method === undefined}>
									No Price
								</option>
								<option value={1} selected={data?.pricing?.method === 0 || data?.pricing?.method === undefined}>
									Fixed Fee
								</option>
								<option value={2} selected={data?.pricing?.method === 1}>
									Units
								</option>
								<option value={3} selected={data?.pricing?.method === 2}>
									Time
								</option>
							</select>
						</div>
						<div className=" w-1/3 px-2">
							<label htmlFor="title" className="form-label form-control-label-sm required">
								Cost
							</label>
							<input
								type="text"
								value={data?.pricing?.cost || undefined}
								onChange={(e) => {
									handleOnChange(e, ['pricing', 'cost'], setData);
								}}
								className="form-control form-control-sm"
								id="pricing_cost"
							/>
							<p className="small_help">Used for [PROJECT_TITLE] smartfields. </p>
						</div>
						<div className=" w-1/3 px-2">
							<label htmlFor="title" className="form-label form-control-label-sm required">
								Promotion
							</label>
							<input
								type="text"
								value={data?.pricing?.promotion || undefined}
								onChange={(e) => {
									handleOnChange(e, ['pricing', 'promotion'], setData);
								}}
								className="form-control form-control-sm"
								id="pricing_promotion"
							/>
							<p className="small_help">Used for [PROJECT_TITLE] smartfields. </p>
						</div>
					</fieldset>
				</form>
			</div>

			{/* 
			<div className=" flex">
					<form className="row g-3 needs-validation flex flex-wrap flex-row " method="post" noValidate onSubmit={handleSubmit}>
				
					</form>
			</div> */}
		</div>
	);
};

export default ContactEdit;
