import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface viewState {
	value: {
		state: number;
		type?: number;
		_id?: string;
	};
}

const initialState: viewState = {
	value: {
		state: 0,
	},
};

const viewSlice = createSlice({
	name: 'example',
	initialState,
	reducers: {
		update: (
			state,
			action: PayloadAction<{
				state: number;
				type?: number;
				_id?: string;
			}>
		) => {
			state.value = action.payload;
		},
	},
});

export const { update } = viewSlice.actions;

export default viewSlice.reducer;
