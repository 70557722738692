import { useEffect, useState } from 'react';
import { CONTACT_PERSON, STATE_DASHBOARD } from '../../functions/Constants';
import Contacts, { Contact } from '../../api/Contacts';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../state/store';
import { update } from '../../state/viewSlice';

interface Props {
	_id: string;
}

const ManageInit: React.FC<Props> = ({ _id }) => {
	const [data, setData] = useState<Contact>({
		_id: '',
		name: { first: '', middle: '', last: '' },
		dob: '',
		gender: 0,
		email: '',
		mobile: '',
		address: { street: '', city: '', postcode: 0, state: '', country: '' },
		relationship: 0,
		type: CONTACT_PERSON,
		associated: { business: [], person: [] },
		created: {
			date: '',
			by: '',
		},
	});
	useEffect(() => {
		const fetchData = async () => {
			await Contacts.get(_id, setData);
		};

		fetchData();
	}, [_id]);

	const viewState = useSelector((state: RootState) => state.example.value);
	const dispatch = useDispatch();

	return (
		<>
			<p>Manage</p>
			<div className="flex">
				<div className="w-1/4 p-2">
					<div className=" items-center text-center h-80 py-12 px-8">
						<img src="https://picsum.photos/250" className="m-auto rounded-xl border-2 border-slate-100" />
					</div>
				</div>
				<div className=" relative my-12">
					<h1 className=" text-4xl">
						{data && 'name' in data && data.name && 'first' in data.name && data.name.first
							? data.name.first +
								('middle' in data.name && data.name.middle ? data.name.middle : '') +
								('last' in data.name && data.name.last ? ' ' + data.name.last : '') +
								('gender' in data
									? ' (' +
										(data.gender === 0 || data.gender === undefined
											? 'They/Them'
											: data.gender === 1
												? 'He/Him'
												: data.gender == 2
													? 'She/Her'
													: '') +
										')'
									: '')
							: data && 'title' in data && data.title
								? data.title
								: ''}
					</h1>
					{/* TODO - click the field to choose what is shown here */}
					<p className=" text-dark-600 text-2xl cursor-pointer">{data.relationship === 2 && '$637.92 lifetime'}</p>

					<div className=" absolute bottom-0">
						Added{' '}
						{data.created && 'date' in data.created && data.created.date ? new Date(data.created.date).toLocaleString() : ''}
					</div>
				</div>
				<div className="w-1/4 absolute right-0 my-12">
					<div className=" my-2">
						<label className=" font-bold">Email</label>
						<p>{data.email ? data.email : 'Set'}</p>
					</div>
					<div className=" my-2">
						<label className=" font-bold">Phone</label>
						<p>{data.mobile ? data.mobile : 'Set'}</p>
					</div>
					<div className=" my-2">
						<label className=" font-bold">Mobile</label>
						<p>{data.mobile ? data.mobile : 'Set'}</p>
					</div>
					<div className=" my-2">
						<label className=" font-bold">Location</label>
						<p>{'address' in data && data.address && data.address.country ? data.address?.country : 'Set'}</p>
					</div>
				</div>
			</div>
			<div className="flex">
				<div className="w-1/6 bg-purple-50 mx-2 text-center">
					<button className="no-btn">Activity</button>
				</div>
				<div className="w-1/6 bg-purple-50 mx-2 text-center">
					<button className="no-btn">Notes</button>
				</div>
				<div className="w-1/6 bg-purple-50 mx-2 text-center">
					<button className="no-btn">Communication</button>
				</div>
				<div className="w-1/6 bg-purple-50 mx-2 text-center">
					<button className="no-btn">Invoices</button>
				</div>
				<div className="w-1/6 bg-purple-50 mx-2 text-center">
					<button className="no-btn">Projects</button>
				</div>
				<div className="w-1/6 bg-purple-50 mx-2 text-center">
					<button className="no-btn">Advanced</button>
				</div>
			</div>
			<div className=" w-full h-3/6 mt-5 bg-blue-50"></div>
			<div className="absolute bottom-0">
				<button className=" bg-red-500 hover:bg-red-600" onClick={() => dispatch(update({ state: STATE_DASHBOARD, _id: '' }))}>
					Back
				</button>
			</div>
		</>
	);
};

export default ManageInit;
