import { SelectOption } from '../components/Select';

const handleOnChange = (
	e:
		| React.ChangeEvent<HTMLInputElement>
		| React.ChangeEvent<HTMLTextAreaElement>
		| React.ChangeEvent<HTMLSelectElement>
		| React.ChangeEvent<HTMLDivElement>
		| React.KeyboardEvent<HTMLDivElement>
		| SelectOption[]
		| SelectOption,
	fieldPath: string[],
	setData: React.Dispatch<React.SetStateAction<any>>
) => {
	let value: any;
	// Handle custom <Select/> components using multiple
	if (Array.isArray(e)) {
		console.log(e);
		if (e.length > 0 && typeof e[0] === 'object' && 'label' in e[0]) {
			value = e.map((option) => ({ _id: option._id, label: option.label }));
		} else {
			value = e.map((option) => option._id);
		}
	} else {
		console.log(e);
		// Set event value for everything else
		if ('target' in e && 'value' in e.target) {
			console.log(e.target.value);
			value = e.target.value;
			// Handle single <Select/> component
		} else if ('_id' in e && 'label' in e) {
			value = { _id: e._id, label: e.label };
		}
	}
	setData((prevData: any) => {
		let updatedData = { ...prevData };
		let currentLevel: any = updatedData;

		if (fieldPath.length > 0) {
			// Traverse the nested properties and update the value
			for (let i = 0; i < fieldPath.length - 1; i++) {
				const currentFieldName = fieldPath[i];
				currentLevel[currentFieldName] = { ...currentLevel[currentFieldName] };
				currentLevel = currentLevel[currentFieldName];
			}

			currentLevel[fieldPath[fieldPath.length - 1]] = value;
		} else {
			// If there is no fieldPath we will update the top level
			currentLevel['_id'] = value._id;
			currentLevel['label'] = value.label;
		}
		// console.log(fieldPath.length);

		return updatedData;
	});
};

export default handleOnChange;
