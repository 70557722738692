export default () => {
	return (
		<>
			<div className="flex flex-wrap flex-row">
				<div className="w-1/2 p-3">
					<label>Require MFA for all team members (highly reccomended)</label>
					<input type="checkbox" name="mfa" />
				</div>
				<div className="w-1/2 p-3">
					<label>Allow password to be reset</label>
					<input type="checkbox" name="reset" />
				</div>
				<div className="w-1/2 p-3">
					<label>Other</label>
					<select>
						<option>Working from Home</option>
						<option>Working from Office</option>
						<option>Out sick</option>
						<option>On a break</option>
						<option>On Holiday</option>
						<option>Mobile working</option>
						<option>Do not distrub</option>
					</select>
				</div>
			</div>
		</>
	);
};
