import { BsAt, BsFillTrash3Fill, BsTelephoneFill } from 'react-icons/bs';
import handleOnChange from '../../../functions/handleOnChange';
import { Select, SelectOption } from '../../../components/Select';
import { useCallback, useEffect, useState } from 'react';
import Contacts, { Contact, relationshipColors } from '../../../api/Contacts';
import { _id } from '../../../functions/Constants';
import { catchError } from '../../../functions/handleLoginAuth';
import { useToast } from '../../../components/toast/ToastService';

export interface ContactAssociate {
	_id: _id;
	title: string;
	label: string;
}

interface EditAssociateProps {
	data: ContactAssociate;
	options: SelectOption[];
	setData: (newData: ContactAssociate) => void;
	handleSubmit: () => void;
	handleDelete: () => void;
	submitLock: boolean;
	onCancel: () => void;
}

export default ({ dataState }: { dataState: [Contact, React.Dispatch<React.SetStateAction<Contact>>] }) => {
	const [contact, SetContact] = dataState;
	const [editingAssociate, setEditingAssociate] = useState<ContactAssociate | null>(null); // Track currently edited note
	const [data, setData] = useState<ContactAssociate>({ _id: '', title: '', label: '' });
	const [contactsList, setContactsList] = useState<SelectOption[]>([{ _id: '', label: '' }]);
	const [submitLock, setSubmitLock] = useState<boolean>(false);
	const toast = useToast();

	useEffect(() => {
		async function fetchObjects() {
			const listBusiness = await Contacts.list.business();
			const contactsOptions: SelectOption[] = listBusiness.map((contact: Contact) => ({
				label: `${
					contact && 'name' in contact && contact.name && 'first' in contact.name
						? contact.name.first
						: contact && 'title' in contact && contact.title
							? contact.title
							: ''
				} ${contact && 'name' in contact && contact.name && 'last' in contact.name ? contact.name.last : ''}`,
				_id: contact._id ? contact._id : '',
			}));

			setContactsList(contactsOptions);
		}
		fetchObjects();
	}, [contact._id]);

	const processEditNote = useCallback((associate: ContactAssociate) => {
		setEditingAssociate(associate);
		setData({ ...associate }); // Pre-populate edit form with note data
	}, []);

	const cancelEdit = useCallback(() => {
		setEditingAssociate(null);
		setData({ _id: '', title: '', label: '' }); // Reset edit state
	}, []);

	const handleDelete = useCallback(() => {
		const updatedAssociate = { ...data }; // Create a copy to avoid mutation
		if (editingAssociate && updatedAssociate) {
			updatedAssociate._id = editingAssociate._id;
			try {
				// Contacts.notes.delete(contact._id, data, toast);
			} catch (error) {
				catchError(error);
			}
		} else {
			const toastId = toast.open(
				<div className=" flex gap-2 bg-red-300 text-emerald-500 p-4 rounded-lg shadow-lg">
					<div></div>
					<div>
						<p>Error!</p>
						<p>Please try again.</p>
					</div>
				</div>,
				8000
			);
		}
	}, [data, editingAssociate, contact._id]);

	const handleSubmit = useCallback(async () => {
		setSubmitLock(true);
		if (data._id === '' && data.title === '') {
			const toastId = toast.open(
				<div className=" flex gap-2 bg-red-300 text-red-500 p-4 rounded-lg shadow-lg">
					<div></div>
					<div>
						<p>Missing Fields!</p>
						<p>Please complete all required fields.</p>
					</div>
				</div>,
				5000
			);
		} else {
			const updatedAssociate = { ...data }; // Create a copy to avoid mutation
			if (editingAssociate && data._id) {
				updatedAssociate._id = editingAssociate._id;
				const response = await Contacts.associate.put(contact._id, updatedAssociate);
				if (response._id !== '') {
					// TODO - When a note is created, flip around to show the 'note card'
					if (contact.associated.business) {
						const noteIndex = contact.associated.business.findIndex((note) => note._id === response._id);

						// Replace the old note with the updated note
						if (noteIndex !== -1) {
							contact.associated.business[noteIndex] = { ...response, contact };
						}
					} else {
						contact.associated.business = [{ ...response, contact }];
					}
				}
				setEditingAssociate(null); // Clear buffer
				setData({ _id: '', title: '', label: '' }); // Clear buffer
				const toastId = toast.open(
					<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
						<div></div>
						<div>
							<p>Note updated!</p>
						</div>
					</div>,
					8000
				);
			} else {
				const response = await Contacts.associate.post(contact._id, data);
				if (response._id !== '') {
					// TODO - When a note is created, flip around to show the 'note card'
					if (contact.associated?.business) {
						contact.associated.business = [...contact.associated?.business, { ...response, contact }];
					} else {
						contact.associated.business = [{ ...response, contact }];
					}
				}
				setData({ _id: '', title: '', label: '' }); // Clear buffer
				const toastId = toast.open(
					<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
						<div></div>
						<div>
							<p>Note added!</p>
						</div>
					</div>,
					8000
				);
			}
		}
		setSubmitLock(false);
	}, [data, editingAssociate, contact._id]);

	return (
		<>
			<div className="flex flex-wrap flex-row">
				<div className="w-full flex">
					{/* TODO - Flip animation when clicked to reveal settings */}
					{/* <span className="border-2 border-slate-300 bg-slate-50 h-[32em] w-[24em]  cursor-pointer min-w-32 block mx-2 relative">
						<img src="https://picsum.photos/160" className="m-auto py-1 rounded-2xl border-2 border-slate-100 " />
						<div className="p-4">
							<h3 className="text-xl">Network North Pty Ltd</h3>
							<p className="text-lg">Software Engineer</p>
						</div>
						<div className="p-4">
							<ul>
								<li>
									<span>Networknorth.com.au</span>
								</li>
								<li>
									<BsAt size={18} className="inline mx-1" />
									<span>hello@Networknorth.com.au</span>
								</li>
								<li>
									<BsTelephoneFill size={16} className="inline mx-1" />
									<span>04268751325</span>
								</li>
								<li className="absolute bottom-0">
									<div className="mb-1">
										<span>Since 12 September 2022</span>
									</div>
								</li>
							</ul>
						</div>
					</span> */}
					{/* TODO - Flip animation when clicked to reveal settings */}
					{/* <span className="border-2 border-slate-200 bg-stone-100 h-[32em] w-[24em] cursor-pointer min-w-32 block mx-2 relative">
						<div className=" top-1/3 m-auto block relative">
							<p className=" py-4 text-center text-lg font-bold">Add work profile</p>
							<BsPlusCircleDotted size={48} className=" m-auto" />
						</div>
					</span> */}
					{/* <span className="border-2 border-slate-200 bg-stone-50 h-[32em] w-[24em] cursor-pointer min-w-32 block mx-2 relative p-4">
						<div className=" flex flex-col">
							<label htmlFor="associated">Business</label>
							<Select
								value={{ _id: '', label: '' }}
								onChange={(e) => {
									handleOnChange(e, ['associated', 'business'], setData);
								}}
								options={contactsList}
							/>
						</div>
						<div className=" flex flex-col mt-8">
							<label htmlFor="associated">Job Title</label>
							<input
								className=""
								type="text"
								id="associated"
								name="associated"
								value={''}
								onChange={(e) => handleOnChange(e, ['associated', 'business'], setData)}
								onClick={(e) => e.stopPropagation()} // Prevent parent onClick from firing
							/>
						</div>
					</span> */}
				</div>
			</div>

			{/* BREAKPOINT HERE  */}

			<div className="grid gap-4 grid-cols-3">
				{/* <div className="flex gap-4 flex-wrap"> */}
				{contact.associated &&
					contact.associated.business &&
					contact.associated.business.map((business: any, index: number) => (
						// TODO - Allow business colour
						<div
							key={index} // Use business ID for efficient keying
							className={`relative flex flex-wrap flex-col w-auto min-w-60 px-4 py-4border-2 cursor-pointer border-2 border-slate-200 bg-stone-50 mx-2 p-4 ${
								editingAssociate === business ? 'editing' : ''
							}`}
							onClick={() =>
								editingAssociate && editingAssociate._id == business._id ? cancelEdit() : processEditNote(business)
							}
						>
							{/* TODO - When the business is clicked, spin it around revealing the edit settings */}
							{editingAssociate && editingAssociate._id === business._id ? ( // Edit business
								<EditAssociate // Render editNote component when editing
									data={data}
									options={contactsList}
									setData={setData}
									handleSubmit={handleSubmit}
									handleDelete={handleDelete}
									submitLock={submitLock}
									onCancel={cancelEdit} // Added cancel functionality
								/>
							) : (
								// Show Note
								<>
									<div
										className={`contact-relationship contact-relationship-${relationshipColors[contact.relationship ?? 0]}`}
									>
										{/* <img src="https://picsum.photos/160" className="m-auto py-1 rounded-2xl border-2 border-slate-100 " /> */}
										<div className="p-4">
											<h3 className="text-xl" onClick={(e) => e.stopPropagation()}>
												{business._id.title}
											</h3>
											<p className="text-lg" onClick={(e) => e.stopPropagation()}>
												{business.title}
											</p>
										</div>
										<div className="p-4">
											<ul>
												{business._id.website && (
													<li onClick={(e) => e.stopPropagation()}>
														<span>{business._id.website}</span>
													</li>
												)}
												{business._id.email && (
													<li onClick={(e) => e.stopPropagation()}>
														<BsAt size={18} className="inline mx-1" />
														<span>{business._id.email}</span>
													</li>
												)}
												{business._id.phone ||
													(business._id.mobile && (
														<li onClick={(e) => e.stopPropagation()}>
															<BsTelephoneFill size={16} className="inline mx-1" />
															<span>{business._id.phone || business._id.mobile}</span>
														</li>
													))}
												{/* <li className="absolute bottom-0">
												<div className="mb-1">
													<span>Since 12 September 2022</span>
												</div>
											</li> */}
											</ul>
										</div>
										{/* <p className="text-xl font-bold ">{business.title}</p>
									<p className="pb-12  pre-line whitespace-pre-line">{business.body}</p>
									<div className="absolute bottom-2">
										<span>Created {new Date(business.created.date).toLocaleString('en-AU')} by Drae Ockenden</span>
									</div> */}
									</div>
								</>
							)}
						</div>
					))}
				{!editingAssociate && (
					<div className="relative flex flex-wrap flex-col w-auto min-w-60 px-4 py-4border-2 cursor-pointer border-2 border-slate-200 bg-stone-50 mx-2 p-4">
						{/* TODO - maybe show a blank area with a "click to add" type thing that the edit settings can switch positions with */}
						<EditAssociate // Render editNote component when editing
							data={data}
							options={contactsList}
							setData={setData}
							handleSubmit={handleSubmit}
							handleDelete={handleDelete}
							submitLock={submitLock}
							onCancel={cancelEdit} // Added cancel functionality
						/>
					</div>
				)}
			</div>
		</>
	);
};

const EditAssociate = ({ data, options, setData, handleSubmit, handleDelete, submitLock, onCancel }: EditAssociateProps) => {
	return (
		<div className="">
			<div className=" flex flex-col">
				<label htmlFor="associated">Business</label>
				<Select
					value={data && '_id' in data && data._id ? { _id: data._id, label: data.label } : { _id: '', label: '' }}
					onChange={(e) => {
						handleOnChange(e, [], setData);
					}}
					onClick={(e) => e.stopPropagation()} // Prevent parent onClick from firing
					options={options}
				/>
			</div>
			<div className=" flex flex-col mt-8">
				<label htmlFor="associated">Job Title</label>
				<input
					className=""
					type="text"
					id="associated"
					name="associated"
					value={data.title}
					onChange={(e) => handleOnChange(e, ['title'], setData)}
					onClick={(e) => e.stopPropagation()} // Prevent parent onClick from firing
				/>
			</div>
			<div className="flex justify-end mt-2">
				{data._id && <BsFillTrash3Fill size={24} onClick={handleDelete} />}
				{data._id && (
					<button className="mr-2 bg-red-300 text-white rounded-lg hover:bg-red-400" onClick={onCancel}>
						Cancel
					</button>
				)}
				<button className="bg-green-300 text-white rounded-lg hover:bg-green-400" onClick={handleSubmit} disabled={submitLock}>
					Save
				</button>
			</div>
		</div>
	);
};
